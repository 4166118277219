// noinspection EqualityComparisonWithCoercionJS

import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {Gear, InfoCircleFill, Power} from 'react-bootstrap-icons';
import {
    addParameterIfChanged,
    alarmsExist,
    getParameterToShow,
    isBMSStartPriority,
    updateFieldIfChanged,
    validateParameter
} from "../../../../helpers/settingsHelper.js";
import ComboBoxWithMessages from "../../../../components/shared/comboboxwithmessages/ComboBoxWithMessages.js";
import SwitchGroup from "../../../shared/switchgroup/SwitchGroup.js";
import InputGroupWithMessages from "../../../../components/shared/inputgroupwithmessages/InputGroupWithMessages.js";
import {cloneDeep} from "lodash";
import PopOverDialog from "../../../../components/shared/popoverdialog/PopOverDialog.js";
import {paramNames} from "../../../parameters/parameterSpec.js";
import {addToast} from "../../../../helpers/reduxstore/reducers/toastReducer.js";
import {useDispatch} from "react-redux";
import {hasAnyRole} from "../../../../helpers/authHelper.js"
import {useMsal} from "@azure/msal-react"
import {toastTypes,roleConstants} from "../../../../helpers/constants.js";
import DeviceConfigurationRow
    from '../../../../components/dashboard/dashboardstatusbox/devicestatus/configurations/configurationrow/DeviceConfigurationRow.js';
import DeviceConfigTopInformation from "../../../shared/topinformation/DeviceConfigTopInformation.js";

/** Displays the configuration page for operational settings
 * @param {object} device device object
 * @param {string} className classes for the component wrapper
 * @param {function} save save function to trigger displaying group selector or confirmation (for single device)
 */
export default function JEOperationSettings({device, className, save}) {
    const {t} = useTranslation(['settingspage', 'dashboard', 'telemetry', 'common'])
    const dispatch = useDispatch()
    const {accounts} = useMsal()
    const [oldDeviceState, setOldDeviceState] = useState(cloneDeep(device))
    const [operationRequest, setOperationRequest] = useState({value: -1})
    const [operationRequestOptionsList, setOperationRequestOptionsList] = useState(generateOperationRequestsOptionsList(device))
    const [airflow, setAirflow] = useState(getParameterToShow(device, paramNames.udf, t))
    const [reboot, setReboot] = useState(getParameterToShow(device, paramNames.reboot, t))
    const [panelStartTimeout, setPanelStartTimeout] = useState(getParameterToShow(device, paramNames.panel_start_timeout, t))
    const [panelOffTimeout, setPanelOffTimeout] = useState(getParameterToShow(device, paramNames.panel_off_timeout, t))
    const [overrideTime, setOverrideTime] = useState(getParameterToShow(device, paramNames.manual_override_time, t))
    const requiredFirmwareVersion = 6251
    const systemMode = parseInt(device?.latest_telemetry?.[paramNames.system_mode])
    const startedByValue = device.device_twin.reported.parameters[paramNames.started_by]
    const firmwareVersion = parseInt(device.device_twin.reported.applicationVersion)
    const startedByText = startedByValue == 5 ?
        <span>CO₂</span> : t('telemetry:StartedBy_' + startedByValue)


    function onlyShowOperationRequest() {
        return firmwareVersion < requiredFirmwareVersion;
    }

    function getOperationRequestParam() {
        return firmwareVersion >= requiredFirmwareVersion ? paramNames.operating_mode_request_airlinq_online : paramNames.operating_mode_request;
    }    

    function showPeriodDifferentSystemModeInfo() {
        if (device?.period_list && device?.period_active_id !== "default") {
            const activePeriod = device?.period_list.find(p => p.id === device?.period_active_id)
            if (parseInt(operationRequest.value) !== -1 && activePeriod && activePeriod.system_mode !== parseInt(operationRequest.value)) return true
        }
        return false
    }

    function generateOperationRequestsOptionsList(device) {
        let disableOff = true
        let disableStandby = true
        let disableManualStart = true
        let disableHolidayMode = true
        let disableAuto = true;

        if (device.device_twin?.reported?.parameters) {
            const normalVentilationParam = parseInt(device.device_twin.reported.parameters[paramNames.normal_ventilation_request] ?? 0)
            const holidayModeRequestParam = parseInt(device.device_twin.reported.parameters[paramNames.holiday_moderequest] ?? 0)
            const stopParam = parseInt(device.device_twin.reported.parameters[paramNames.stop] ?? 0)
            // const panelOffAllowed = parseInt(device.device_twin.reported.parameters[paramNames.panel_off_allowed] ?? 0)
            const manualOverrideActive = parseInt(device.latest_telemetry[paramNames.system_condition]?.[9])
            const nightCoolRequestParam = parseInt(device.device_twin.reported.parameters[paramNames.night_cool_request] ?? 0)
            const panelOffTimeoutParam = parseInt(device.device_twin.reported.parameters[paramNames.panel_off_timeout] ?? 0)
            const offWithTimeoutRequest = parseInt(device.device_twin.reported.parameters[paramNames.off_with_timeout_request] ?? 0)

            const systemMode = parseInt(device.latest_telemetry?.[paramNames.system_mode])

            if (normalVentilationParam != 0 || holidayModeRequestParam != 0 || stopParam != 0 || manualOverrideActive != 0 || systemMode == 0) disableAuto = false
            if (normalVentilationParam == 0 || holidayModeRequestParam != 0 || stopParam != 0 || nightCoolRequestParam != 0 || manualOverrideActive != 0 || systemMode == 0) disableManualStart = false
            if (normalVentilationParam != 0 || panelOffTimeoutParam > 0 || holidayModeRequestParam != 0 || nightCoolRequestParam != 0 || offWithTimeoutRequest == 0 || manualOverrideActive != 0 || systemMode == 6) disableStandby = false

            // TODO: reevaluate this line. It disables Off option when it should not according to Mikkel (eg. see https://online.airlinq.eu/device/0711126/configurations/operation)
            // if (panelOffAllowed == 1 && (stopParam == 0 || offWithTimeoutRequest != 0)) disableOff = false
            if (stopParam == 0 || offWithTimeoutRequest != 0) disableOff = false // reverted to old line

            if (holidayModeRequestParam == 0 || systemMode != 7) disableHolidayMode = false
        }

        return [
            {id: -1, option: t('settingspage:parameters.mode_options.Choose_Operation_Request'), disabled: true},
            {id: 0, option: t('settingspage:parameters.mode_options.OFF'), disabled: disableOff},
            {id: 1, option: t('settingspage:parameters.mode_options.Standby'), disabled: disableStandby},
            {id: 2, option: t('settingspage:parameters.mode_options.Manual_Start'), disabled: disableManualStart},
            {id: 3, option: t('settingspage:parameters.mode_options.Holiday_Mode'), disabled: disableHolidayMode},
            {id: 4, option: t('settingspage:parameters.mode_options.Auto'), disabled: disableAuto}
        ]
    }

    // When device is updated after write, update parameters to show / hide messages
    useEffect(() => {
        const desiredOperationModeRequest = device.device_twin.desired?.parameters?.[getOperationRequestParam()]

        const messages = [{
            type: "warning",
            text: t('settingspage:setpoints_settings.messages.waiting_for_update')
        }]

        if (desiredOperationModeRequest && (operationRequest.value == -1 || desiredOperationModeRequest == operationRequest.value)) {
            setOperationRequest({value: desiredOperationModeRequest, messages: messages});
        }else{
            setOperationRequest({value: operationRequest.value, messages: []});
        }

        setOperationRequestOptionsList(generateOperationRequestsOptionsList(device))
        updateFieldIfChanged(setAirflow, oldDeviceState, device, paramNames.udf, t)
        updateFieldIfChanged(setOverrideTime, oldDeviceState, device, paramNames.manual_override_time, t)
        updateFieldIfChanged(setPanelStartTimeout, oldDeviceState, device, paramNames.panel_start_timeout, t)
        updateFieldIfChanged(setPanelOffTimeout, oldDeviceState, device, paramNames.panel_off_timeout, t)
        updateFieldIfChanged(setReboot, oldDeviceState, device, paramNames.reboot, t)
        setOldDeviceState(cloneDeep(device))
    }, [device])

    /**
     * validates parameters. Adds messages to viewmodels if fail. returns true if all is validated, otherwise false.
     * @returns {boolean}
     */
    const validateParameters = () => {
        let validatedList = []
        if (!onlyShowOperationRequest()) {
            validatedList.push(validateParameter(paramNames.udf, airflow, setAirflow, t))
            validatedList.push(validateParameter(paramNames.panel_start_timeout, panelStartTimeout, setPanelStartTimeout, t))
            validatedList.push(validateParameter(paramNames.panel_off_timeout, panelOffTimeout, setPanelOffTimeout, t))
            validatedList.push(validateParameter(paramNames.manual_override_time, overrideTime, setOverrideTime, t))
        }
        return validatedList.every(v => v)
    }

    const getValuesToSave = () => {
        const updated_parameters = {}
        if (operationRequest.value != -1) {
            updated_parameters[getOperationRequestParam()] = operationRequest.value       
        }
       
        if (!onlyShowOperationRequest()) {
            addParameterIfChanged(updated_parameters, paramNames.udf, airflow.value, device)
            addParameterIfChanged(updated_parameters, paramNames.panel_start_timeout, panelStartTimeout.value, device)
            addParameterIfChanged(updated_parameters, paramNames.panel_off_timeout, panelOffTimeout.value, device)
            addParameterIfChanged(updated_parameters, paramNames.manual_override_time, overrideTime.value, device)
        }
        addParameterIfChanged(updated_parameters, paramNames.reboot, reboot.value, device)
        return updated_parameters
    }

    function saveIfValidated(saveForGroup) {
        if (!validateParameters()) return
        const valuesToSave = getValuesToSave()
        if (Object.keys(valuesToSave).length === 0) {
            dispatch(addToast({
                id: "OperationSettingsInfo",
                type: toastTypes.info,
                title: "No changes",
                body: "No changes to parameters",
                timeSeconds: 3
            }))
            return
        }
        save(valuesToSave, saveForGroup);
    }

    function getDisableAirflow() {
        //devices only allow to override airflow when device is running: (2, 6, 7, 8)
        const systemModeAllowedAirflowChange = [2, 6, 7, 8]
        if (systemModeAllowedAirflowChange.indexOf(systemMode) === -1)
            return true
        return device.slave
    }

    function getDisableOperationRequest() {
        if (device.slave) return true
        return !isBMSStartPriority(device) || alarmsExist(device);
    }

    return (
        <div className={className}>
            <DeviceConfigTopInformation className={'d-xs-none'} title={t('settingspage:operation_settings.title')}
                            subTitle={onlyShowOperationRequest() ? t('settingspage:operation_settings.description_short') : t('settingspage:operation_settings.description')}/>

            <div className="d-flex desktop-float-left">
                <div className="settings-item-container">
                    <ComboBoxWithMessages
                        className='p-4 w-330px m-auto'
                        label={t('settingspage:parameters.sys_mode')}
                        disabled={getDisableOperationRequest()}
                        inputValue={(e) => setOperationRequest({value: e.target.value})}
                        name='operationRequest'
                        value={operationRequest.value}
                        options={operationRequestOptionsList}
                        messages={operationRequest.messages}
                        infoelement={<>
                            {showPeriodDifferentSystemModeInfo() && <span className='ms-1 color-warning'>(*)</span>}
                            <PopOverDialog popOverTitel={t('settingspage:parameters.sys_mode')}
                                           popOverContent={t('settingspage:infomodals.sys_mode')}
                                           trigger={['hover', 'touch']} contentAsHtml={true} size={'lg'}/>
                        </>}
                    />

                    <div className='p-4 w-330px py-0 py-xl-0 align-items-center'>
                        <DeviceConfigurationRow title={t('dashboard:status.configuration.system_mode')}
                                                isInstalled={true}
                                                value={device.connected ? t('telemetry:SystemMode_' + systemMode) : "-"}
                                                icon={<Gear fill='#44698e'/>}/>
                    </div>

                    <div className='p-4 w-330px py-0 py-xl-0 align-items-center'>
                        <DeviceConfigurationRow title={t('dashboard:status.configuration.started_by')}
                                                isInstalled={true}
                                                value={device.connected ? startedByText : "-"}
                                                icon={<Power fill='#44698e'/>}/>
                    </div>

                </div>

            </div>

            <div className="mt-sm-3 d-flex flex-wrap">
                {!onlyShowOperationRequest() &&
                    <div className="settings-item-container">
                        <InputGroupWithMessages
                            label={t('settingspage:parameters.airflow')}
                            className='p-4 w-200px m-auto'
                            value={airflow.value}
                            unit='%'
                            inputValue={(e) => setAirflow({value: e.target.value})}
                            placeholder='%'
                            disabled={getDisableAirflow()}
                            messages={airflow.messages}
                            type='number'
                            min={0}
                            max={100}
                            infoelement={<PopOverDialog popOverTitel={t('settingspage:parameters.airflow')}
                                                        popOverContent={t('settingspage:infomodals.airflow')}
                                                        trigger={['hover', 'touch']}
                                                        contentAsHtml={true}/>}
                        />
                    </div>
                }
                {!onlyShowOperationRequest() &&
                    <div className="settings-item-container">
                        <InputGroupWithMessages
                            label={t('settingspage:parameters.override_time')}
                            className='p-4 w-200px m-auto'
                            value={overrideTime.value}
                            unit={t('common:units.hours')}
                            inputValue={(e) => setOverrideTime({value: e.target.value})}
                            disabled={device.slave}
                            placeholder={t('common:units.hours')}
                            type='number'
                            messages={overrideTime.messages}
                            infoelement={<PopOverDialog popOverTitel={t('settingspage:parameters.override_time')}
                                                        popOverContent={t('settingspage:infomodals.override_time')}
                                                        trigger={['hover', 'touch']}
                                                        contentAsHtml={true}/>}
                        />
                    </div>
                }
                {!onlyShowOperationRequest() &&
                    <div className="settings-item-container">
                        <InputGroupWithMessages
                            label={t('settingspage:parameters.panel_start_timeout')}
                            className='p-4 w-200px m-auto'
                            value={panelStartTimeout.value}
                            unit={t('common:units.hours')}
                            inputValue={(e) => setPanelStartTimeout({value: e.target.value})}
                            placeholder={t('common:units.hours')}
                            disabled={device.slave}
                            messages={panelStartTimeout.messages}
                            type='number'
                            min={0}
                            max={255}
                            infoelement={<PopOverDialog
                                popOverTitel={t('settingspage:parameters.panel_start_timeout')}
                                popOverContent={t('settingspage:infomodals.panel_start_timeout')}
                                trigger={['hover', 'touch']}
                                contentAsHtml={true}/>}
                        />
                    </div>
                }
                {!onlyShowOperationRequest() &&
                    <div className="settings-item-container">
                        <InputGroupWithMessages
                            label={t('settingspage:parameters.panel_off_timeout')}
                            className='p-4 w-200px m-auto'
                            value={panelOffTimeout.value}
                            unit={t('common:units.hours')}
                            inputValue={(e) => setPanelOffTimeout({value: e.target.value})}
                            placeholder={t('common:units.hours')}
                            disabled={device.slave}
                            messages={panelOffTimeout.messages}
                            type='number'
                            min={0}
                            max={255}
                            infoelement={<PopOverDialog
                                popOverTitel={t('settingspage:parameters.panel_off_timeout')}
                                popOverContent={t('settingspage:infomodals.panel_off_timeout')}
                                trigger={['hover', 'touch']}
                                contentAsHtml={true}/>}
                        />
                    </div>
                }
                {
                    hasAnyRole(accounts, [roleConstants.Admin, roleConstants.Technical, roleConstants.TechnicalBasic]) && 
                        <div className="settings-item-container">
                            <div>
                                <SwitchGroup
                                    label={t('settingspage:parameters.reboot')}
                                    className='p-4 w-200px m-auto'
                                    checked={reboot.value == "1"}
                                    onChange={(e) => setReboot({value: e.target.checked ? "1" : "0"})}
                                    messages={reboot.messages}
                                    infoelement={<PopOverDialog popOverTitel={t('settingspage:parameters.reboot')}
                                                                popOverContent={t('settingspage:infomodals.reboot')}
                                                                trigger={['hover', 'touch']}
                                                                contentAsHtml={true}/>}
                                />
                            </div>
                        </div>
                }
            </div>

            <div className="alert alert-info" hidden={!device.slave}>
                <InfoCircleFill className='me-1 mb-1 text-primary'/>{t('settingspage:slave-device-settings')}
                {device.master_serial_number &&
                    <a href={"/device/" + device.master_serial_number + "/configurations/operation"}>{device.master_serial_number}</a>}
            </div>


            {showPeriodDifferentSystemModeInfo() &&
                <div className='p-2 color-warning'>(*): {t('settingspage:period_different_system_mode_info', {
                    userSet: operationRequestOptionsList.find(o => o.id === systemMode)?.option,
                    activePeriodName: device?.period_list?.find(p => p.id === device?.period_active_id)?.name,
                    inPeriod: operationRequestOptionsList.find(o => o.id === device?.period_list?.find(p => p.id === device?.period_active_id)?.system_mode)?.option
                })}
                </div>
            }

            {/*=== Button container*/}
            <div className='d-inline-block float-end p-2'>
                <Button variant='outline-secondary' onClick={() => saveIfValidated(false)}
                        className='me-2'>
                    {t('settingspage:save_device')}</Button>
                <Button variant='outline-secondary' onClick={() => saveIfValidated(true)}>
                    {t('settingspage:save_group')}</Button>
            </div>
        </div>
    )
}