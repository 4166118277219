import React, {useEffect, useState} from "react";
import {DeviceTechnicalTelemetryOverviewItemContainer} from "./DeviceTechnicalTelemetryOverviewItemContainer.js";
import {DeviceTechnicalTelemetryOverviewItem} from "./DeviceTechnicalTelemetryOverviewItem.js";
import {paramNames, paramSpec} from "./parameterSpec.js";
import {getFormattedDate, getFormattedNumber, saveCSV, trimRight} from "../../helpers/helperfunctions.js";
import {useTranslation} from "react-i18next";
import {Button} from "react-bootstrap";
import {FileSpreadsheetFill} from "react-bootstrap-icons";
import {toInteger} from "lodash";
import {isAdmin} from "../../helpers/authHelper.js";
import {useMsal} from "@azure/msal-react";
import UploadSettingsModal from "./modals/UploadSettingsModal.js";
import {isUndefined} from "lodash/lang.js";
import {deviceParams} from "../../helpers/constants.js";

/** Renders technical telemetry overview component
 * @param device Device to use telemetry from
 * @returns {JSX.Element}
 * @constructor
 */
export function JEDeviceTechnicalTelemetryOverview({device}) {
    const {t} = useTranslation(['technicalpage', 'common'])
    const {accounts} = useMsal();
    const [uploadSettingsModalConfigObj, setUploadSettingsModalConfigObj] = useState({})

    function settingIsChanged(key, value) {
        if (isUndefined(device.device_twin.reported.parameters[key])) return true
        const reportedValue = device.device_twin.reported.parameters[key]
        if (reportedValue.length === 16 && reportedValue.split('').every(v => v === '1' || v === '0')) {
            // in the settings files, bitwise are trimmed from right and recorded as base10, eg '1111100000000000' = 31
            //  ,so we do that for the existing values before comparing here
            const trimmedVal = trimRight(reportedValue, '0')
            if (trimmedVal === '') return value !== '0'
            const base10Value = parseInt(trimmedVal, 2)
            return base10Value !== toInteger(value)
        }
        return parseFloat(device.device_twin.reported.parameters[key]) !== parseFloat(value)
    }

    /** @param {string} result */
    async function uploadSettingsFile(result) {
        const decimalSep = findDecimalSeparator(result)

        const uploadedSettingsMap = Object.assign({}, ...result.trim().split("\n")
            .filter(s => s.split(";")[1])
            .map(s => {
            const [key, value] = s.split(";")
            return {[toInteger(key)]: getUploadValue(decimalSep, value)}
        }))

        const readOnlyKeysSet = new Set(["482"])
        const uploadedSettingsDiscardedBecauseReadonlyEntryList = uploadedSettingsMap
            .toEntries()
            .filter(s => readOnlyKeysSet.has(s[0]))
        const uploadedSettingsWithoutReadonlyEntryList = uploadedSettingsMap
            .toEntries()
            .filter(s => !readOnlyKeysSet.has(s[0]))

        const unchangedEntryList = uploadedSettingsWithoutReadonlyEntryList.filter(([key, value]) => !settingIsChanged(key, value))
        const changedEntryList = uploadedSettingsWithoutReadonlyEntryList.filter(([key, value]) => settingIsChanged(key, value))

        setUploadSettingsModalConfigObj({
            uploadedSettingsMap,
            uploadedSettingsDiscardedBecauseReadonlyEntryList,
            uploadedSettingsWithoutReadonlyEntryList,
            unchangedEntryList,
            changedEntryList,
            device,
            isVisible: true
        })

        document.getElementById('uploadSettingsFileInput').value = null // otherwise can only upload once
    }

    useEffect(() => {
        // only admins can see this element, so we check if it is there before adding eventlistener
        const uploadSettingsFileInputEle = document.getElementById('uploadSettingsFileInput')
        if (uploadSettingsFileInputEle) {
            uploadSettingsFileInputEle.addEventListener('change', function () {
                if (this.files.length === 0) return;
                const reader = new FileReader();
                reader.onload = event => uploadSettingsFile(event.target.result);
                reader.readAsText(this.files[0]);
            })
        }
    }, [])

    function downloadSettingsCSV() {
        function getValue(n) {
            const value = device.device_twin.reported.parameters[n]
            // if value is flags cut zeros from end and binary to decimal
            if (value.length === 16 && value.split('').every(v => v === '1' || v === '0')) {
                const trimmedVal = trimRight(value, '0')
                if (trimmedVal === '') return 0
                return parseInt(trimmedVal, 2)
            }
            return device.device_twin.reported.parameters[n].replaceAll(".", ",");
        }

        const csvString = [...Array(690).keys()]
            .map(n => n + 3)
            .filter(n => device.device_twin.reported.parameters[n] && paramSpec[n])
            .map(n => `${n.toString().padStart(3, '0')};${(getValue(n))}`)
            .join("\n")
        saveCSV(`${device.serial_number}.csv`, csvString)
    }

    return <div>
        <UploadSettingsModal configObj={uploadSettingsModalConfigObj} setConfigObj={setUploadSettingsModalConfigObj}/>

        <div className='d-flex justify-content-between align-content-center mb-1'>

            {!isAdmin(accounts) &&
                <div>{t('technicalpage:overview.last_updated')}: {getFormattedDate(device.latest_telemetry["commandTime"])}</div>
            }
            {isAdmin(accounts) &&
                <div className='d-flex'>
                    <div className='me-4'>Sidste opdateret telemetri: {getFormattedDate(device.latest_telemetry["commandTime"])}</div>
                    {device?.device_twin?.reported?.commandTime && <div>Sidste opdateret parametre: {getFormattedDate(device?.device_twin?.reported?.commandTime)}</div>}
                </div>
            }

            {isAdmin(accounts) &&
                <div>
                    <label className="btn btn-outline-secondary me-2">
                        <FileSpreadsheetFill className="me-1"/>{t('technicalpage:overview.upload_settings_btn.text')}
                        <input type="file" id="uploadSettingsFileInput" className="d-none"/>
                        {/* onClick handler is uploadSettingsFile function through roundabout js file eventhandler */}
                    </label>
                    <Button onClick={downloadSettingsCSV} type="button" variant='outline-secondary'>
                        <FileSpreadsheetFill className="me-1"/>{t('technicalpage:overview.download_settings_btn.text')}
                    </Button>
                </div>
            }
        </div>

        <div className="device-parameters-contentbox d-xs-block p-2">

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.system')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_mode]}
                                                      value={device.latest_telemetry[paramNames.system_mode]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={device?.latest_telemetry?.[deviceParams.started_by] ? paramSpec[paramNames.started_by_NAMEDUPLICATE_OF_96] : paramSpec[paramNames.started_by]}
                                                      value={device?.latest_telemetry?.[deviceParams.started_by] ?? device.device_twin?.reported?.parameters?.[deviceParams.started_by_param]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.total_operating_hours]}
                                                      value={device.device_twin.reported.parameters[paramNames.total_operating_hours]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.operating_hours_since_last_service]}
                                                      value={device.device_twin.reported.parameters[paramNames.operating_hours_since_last_service]}
                                                      deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.system_warnings')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_condition]}
                                                      value={device.latest_telemetry[paramNames.system_condition]?.[6]}
                                                      bitmask_index={6} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_condition]}
                                                      value={device.latest_telemetry[paramNames.system_condition]?.[3]}
                                                      bitmask_index={3} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_condition]}
                                                      value={device.latest_telemetry[paramNames.system_condition]?.[4]}
                                                      bitmask_index={4} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_condition]}
                                                      value={device.latest_telemetry[paramNames.system_condition]?.[5]}
                                                      bitmask_index={5} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_condition]}
                                                      value={device.latest_telemetry[paramNames.system_condition]?.[10]}
                                                      bitmask_index={10} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_condition]}
                                                      value={device.latest_telemetry[paramNames.system_condition]?.[11]}
                                                      bitmask_index={11} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_condition]}
                                                      value={device.latest_telemetry[paramNames.system_condition]?.[13]}
                                                      bitmask_index={13} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_condition]}
                                                      value={device.latest_telemetry[paramNames.system_condition]?.[14]}
                                                      bitmask_index={14} deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.flow_setpoints')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.udf]}
                                                      value={device.device_twin.reported.parameters[paramNames.udf]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.requested_flow]}
                                                      value={device.latest_telemetry[paramNames.requested_flow]}
                                                      deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer
                title={t('technicalpage:overview.title.temperature_setpoints')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.udt]}
                                                      value={getFormattedNumber(device.device_twin.reported.parameters[paramNames.udt])}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.requested_temperature]}
                                                      value={getFormattedNumber(device.latest_telemetry[paramNames.requested_temperature])}
                                                      deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.system_conditions')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_condition]}
                                                      value={device.latest_telemetry[paramNames.system_condition]?.[0]}
                                                      bitmask_index={0} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_condition]}
                                                      value={device.latest_telemetry[paramNames.system_condition]?.[1]}
                                                      bitmask_index={1} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_condition]}
                                                      value={device.latest_telemetry[paramNames.system_condition]?.[2]}
                                                      bitmask_index={2} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_condition]}
                                                      value={device.latest_telemetry[paramNames.system_condition]?.[7]}
                                                      bitmask_index={7} deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.system_alarm')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_alarm]}
                                                      value={device.latest_telemetry[paramNames.system_alarm]?.[0]}
                                                      bitmask_index={0} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_alarm]}
                                                      value={device.latest_telemetry[paramNames.system_alarm]?.[1]}
                                                      bitmask_index={1} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_alarm]}
                                                      value={device.latest_telemetry[paramNames.system_alarm]?.[2]}
                                                      bitmask_index={2} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.system_alarm]}
                                                      value={device.latest_telemetry[paramNames.system_alarm]?.[3]}
                                                      bitmask_index={3} deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.supply_fan')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.supply_fan_voltage]}
                                                      value={device.latest_telemetry[paramNames.supply_fan_voltage]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.supply_flow]}
                                                      value={device.latest_telemetry[paramNames.supply_flow]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem
                    item={{type: 'manual', id: '', unit: 'l/s', name_orig: 'Supply Flow'}}
                    value={getFlowLitersPerSecondFromM3PerHour(device.latest_telemetry[paramNames.supply_flow])}
                    deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.supply_fan_rpm]}
                                                      value={device.latest_telemetry[paramNames.supply_fan_rpm]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.supply_air_pressure_difference]}
                                                      value={device.latest_telemetry[paramNames.supply_air_pressure_difference]}
                                                      deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.extraction_fan')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.extraction_fan_voltage]}
                                                      value={device.latest_telemetry[paramNames.extraction_fan_voltage]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.extraction_flow]}
                                                      value={device.latest_telemetry[paramNames.extraction_flow]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem
                    item={{type: 'manual', id: '', unit: 'l/s', name_orig: 'Extraction Flow'}}
                    value={getFlowLitersPerSecondFromM3PerHour(device.latest_telemetry[paramNames.extraction_flow])}
                    deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.extraction_fan_rpm]}
                                                      value={device.latest_telemetry[paramNames.extraction_fan_rpm]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.extracton_air_pressure_difference]}
                                                      value={device.latest_telemetry[paramNames.extracton_air_pressure_difference]}
                                                      deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.heaters')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.pre_heater_percent]}
                                                      value={device.latest_telemetry[paramNames.pre_heater_percent]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.comfort_heater_percent]}
                                                      value={device.latest_telemetry[paramNames.comfort_heater_percent]}
                                                      deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.dampers')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.main_air_damper]}
                                                      value={device.latest_telemetry[paramNames.main_air_damper]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.bypass_damper_percent]}
                                                      value={device.latest_telemetry[paramNames.bypass_damper_percent]}
                                                      deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.temperatures')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.room_temperature]}
                                                      value={getFormattedNumber(device.latest_telemetry[paramNames.room_temperature])}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.inlet_temperature]}
                                                      value={getFormattedNumber(device.latest_telemetry[paramNames.inlet_temperature])}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.outside_temperature_ventilation]}
                                                      value={getFormattedNumber(device.latest_telemetry[paramNames.outside_temperature_ventilation])}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.exhaust_temperature_ventilation]}
                                                      value={getFormattedNumber(device.latest_telemetry[paramNames.exhaust_temperature_ventilation])}
                                                      deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.cooling')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.cooling_percent]}
                                                      value={device.latest_telemetry[paramNames.cooling_percent]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.outside_temperature]}
                                                      value={getFormattedNumber(device.latest_telemetry[paramNames.outside_temperature])}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.evaporator_in_temperature]}
                                                      value={getFormattedNumber(device.latest_telemetry[paramNames.evaporator_in_temperature])}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.evaporator_out_temperature]}
                                                      value={getFormattedNumber(device.latest_telemetry[paramNames.evaporator_out_temperature])}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hotgas_temperature]}
                                                      value={getFormattedNumber(device.latest_telemetry[paramNames.hotgas_temperature])}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.stepper_valve_opening]}
                                                      value={device.latest_telemetry[paramNames.stepper_valve_opening]}
                                                      deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.filter')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.remaining_service_life_percent]}
                                                      value={device.latest_telemetry[paramNames.remaining_service_life_percent]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.filter_remaining_service_life_days]}
                                                      value={device.latest_telemetry[paramNames.filter_remaining_service_life_days]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.remaining_service_life_hours]}
                                                      value={device.latest_telemetry[paramNames.remaining_service_life_hours]}
                                                      deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.sensors')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.co2_level_room]}
                                                      value={device.latest_telemetry[paramNames.co2_level_room]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.air_quality_tvoc]}
                                                      value={device.latest_telemetry[paramNames.air_quality_tvoc]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.relative_humidity_supply_air]}
                                                      value={device.latest_telemetry[paramNames.relative_humidity_supply_air]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.relative_humidity_extraction_air]}
                                                      value={device.latest_telemetry[paramNames.relative_humidity_extraction_air]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.air_handling_unit_power_consumption]}
                                                      value={device.latest_telemetry[paramNames.air_handling_unit_power_consumption]}
                                                      deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.cooling_unit_power_consumption]}
                                                      value={device.latest_telemetry[paramNames.cooling_unit_power_consumption]}
                                                      deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.firmware')}>
                <DeviceTechnicalTelemetryOverviewItem
                    item={{type: 'manual', id: '', unit: '', name_orig: 'Firmware Version'}}
                    value={device.device_twin.reported.applicationVersion}/>
                <DeviceTechnicalTelemetryOverviewItem
                    item={{type: 'manual', id: '', unit: '', name_orig: 'Parameter Version'}}
                    value={device.device_twin.reported.parameterVersion}/>
                <DeviceTechnicalTelemetryOverviewItem
                    item={{type: 'manual', id: '', unit: '', name_orig: 'Bootloader Version'}}
                    value={device.device_twin.reported.bootloaderVersion}/>
                <DeviceTechnicalTelemetryOverviewItem
                    item={{type: 'manual', id: '', unit: '', name_orig: 'Ethernet Serial'}}
                    value={device.device_twin.reported.ethernetViaDeviceSerial}/>
                {device.slave && <DeviceTechnicalTelemetryOverviewItem
                    item={{type: 'manual', id: '', unit: '', name_orig: 'Master Serial'}}
                    value={device.master_serial_number ?? "N/A"}/>}
                <DeviceTechnicalTelemetryOverviewItem
                    item={{type: 'manual', id: '', unit: '', name_orig: 'Gateway Serial'}}
                    value={device.device_twin.reported.gatewaySerial}/>
                <DeviceTechnicalTelemetryOverviewItem
                    item={{type: 'manual', id: '', unit: '', name_orig: 'Ethernet Firmware Ver.'}}
                    value={device.device_twin.reported.ethernetFirmwareVersion ?? "N/A"}/>
                <DeviceTechnicalTelemetryOverviewItem
                    item={{type: 'manual', id: '', unit: '', name_orig: 'Ethernet Bootloader Ver.'}}
                    value={device.device_twin.reported.ethernetBootloaderVersion ?? "N/A"}/>
                <DeviceTechnicalTelemetryOverviewItem item={{type: 'manual', id: '', unit: '', name_orig: 'MAC'}}
                                                      value={device.device_twin.reported.macAdr ?? "N/A"}/>
                <DeviceTechnicalTelemetryOverviewItem item={{type: 'manual', id: '', unit: '', name_orig: 'Ip'}}
                                                      value={device.device_twin.reported.ipAdr ?? "N/A"}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            {/*Empty spot for now*/}
            <div></div>

            <DeviceTechnicalTelemetryOverviewItemContainer
                title={t('technicalpage:overview.title.temperature_sensors')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[0]}
                                                      bitmask_index={0} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[1]}
                                                      bitmask_index={1} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[2]}
                                                      bitmask_index={2} deviceConnected={device.connected}
                                                      valueOverride={device.installed_components.cooler ? "" : "N/A"}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[3]}
                                                      bitmask_index={3} deviceConnected={device.connected}
                                                      valueOverride={device.installed_components.cooler ? "" : "N/A"}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[4]}
                                                      bitmask_index={4} deviceConnected={device.connected}
                                                      valueOverride={device.installed_components.cooler ? "" : "N/A"}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[5]}
                                                      bitmask_index={5} deviceConnected={device.connected}
                                                      valueOverride={device.installed_components.cooler ? "" : "N/A"}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[6]}
                                                      bitmask_index={6} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[7]}
                                                      bitmask_index={7} deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.misc')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[11]}
                                                      bitmask_index={11} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[23]}
                                                      bitmask_index={23} deviceConnected={device.connected}
                                                      valueOverride={!device.installed_components.tvoc_sensor ? "N/A" : ""}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[20]}
                                                      bitmask_index={20} deviceConnected={device.connected}
                                                      valueOverride={!device.installed_components.humidity_control_sensor ? "N/A" : ""}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[21]}
                                                      bitmask_index={21} deviceConnected={device.connected}
                                                      valueOverride={!device.installed_components.humidity_control_sensor ? "N/A" : ""}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer title={t('technicalpage:overview.title.fans')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[8]}
                                                      bitmask_index={8} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[9]}
                                                      bitmask_index={9} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[10]}
                                                      bitmask_index={10} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[12]}
                                                      bitmask_index={12} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[13]}
                                                      bitmask_index={13} deviceConnected={device.connected}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

            <DeviceTechnicalTelemetryOverviewItemContainer
                title={t('technicalpage:overview.title.inverter_controlled_cooling')}>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[14]}
                                                      bitmask_index={14} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[15]}
                                                      bitmask_index={15} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[16]}
                                                      bitmask_index={16} deviceConnected={device.connected}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[17]}
                                                      bitmask_index={17} deviceConnected={device.connected}
                                                      valueOverride={(device.installed_components.electric_comfort_heater || device.installed_components.water_comfort_heater) ? "" : "N/A"}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[18]}
                                                      bitmask_index={18} deviceConnected={device.connected}
                                                      valueOverride={(device.installed_components.electric_comfort_heater || device.installed_components.water_comfort_heater) ? "" : "N/A"}/>
                <DeviceTechnicalTelemetryOverviewItem item={paramSpec[paramNames.hardware_errors]}
                                                      value={device.latest_telemetry[paramNames.hardware_errors]?.[19]}
                                                      bitmask_index={19} deviceConnected={device.connected}
                                                      valueOverride={(device.installed_components.electric_comfort_heater || device.installed_components.water_comfort_heater) ? "" : "N/A"}/>
            </DeviceTechnicalTelemetryOverviewItemContainer>

        </div>
    </div>
}

function getFlowLitersPerSecondFromM3PerHour(flow_m3h) {
    // 1 m^3/h = 100^3/(10^3*60^2) = 0,277778 L/s
    return (flow_m3h * 0.277778).toFixed(2)
}

/** Find decimal separator in string of key:value pairs. The one with the most should be decimal separator.
 * @param {string} result
 * @returns {undefined} */
function findDecimalSeparator(result) {
    const commaNum = result.split('').filter(c => c === ',').length
    const dotNum = result.split('').filter(c => c === '.').length
    return dotNum > commaNum ? "." : ","
}

/** transform value to '.' decimal and cut off unnecessary zeroes from end
 * Examples - if decimalSep is ',': 3.000,00 -> 3000 and 2,2 -> 2.2
 * @param {string} decimalSep ',' or '.'
 * @param {string} value
 * @returns {string} */
function getUploadValue(decimalSep, value) {
    const dotDecimalValue = decimalSep === ","
        ? value.trim().replaceAll(".", "").replaceAll(",", ".")
        : value.trim().replaceAll(",", "")
    return parseFloat(dotDecimalValue)
}

