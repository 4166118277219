// noinspection EqualityComparisonWithCoercionJS

import {deviceParams, periodErrors, scheduleBarColors, settingsModalTypes} from '../../../../helpers/constants.js'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from "react-bootstrap"
import {createUUID, isAwareDevice, setCharAt} from "../../../../helpers/helperfunctions.js"
import {getParameterToShow} from "../../../../helpers/settingsHelper.js"
import {cloneDeep, difference, random} from "lodash"
import {paramNames, paramSpec} from "../../../parameters/parameterSpec.js"
import {DeviceTimerPeriod} from "./DeviceTimerPeriod.js"
import {ChevronDoubleDown, PlusLg} from "react-bootstrap-icons"
import {validateMonthDayNoOverlap} from "./periodHelper.js"
import DeviceTimerPeriodsChart from "./DeviceTimerPeriodsChart.js";
import {useSelector} from "react-redux";
import {awareParamNames} from "../../../parameters/awareParamSpec.js";
import {isUndefined} from "lodash/lang.js";
import DeviceConfigTopInformation from "../../../shared/topinformation/DeviceConfigTopInformation.js";

/** Displays the configuration page for setpoints settings
 * @param {object} device device object
 * @param {string} className classes for the component wrapper
 * @param {function} save
 */
export default function DeviceTimersSettingsWithPeriods({device, className, save}) {
    const {t} = useTranslation(['settingspage', 'common'])
    const TIMER_PARAMETERS_START = 440
    const MAX_SCHEDULE_NUM = isAwareDevice(device.serial_number) ? 0 : 7
    const MODE_ENABLED_INDEX = 4
    const MODE_SHOWN_INDEX = 5
    const DISABLED_HIDDEN_FLAGBITS = '1000000000000000' // standard ventilation, disabled, hidden
    const periodColors = ["#eeeeee", "#68a8de", "#2ecb73", "#cad55a", "#ff6432", "#ff5733", "#33fffe", "#ff33ba"]
    const userCanEdit = true // TODO: use line below once price strategy has been determined. Remember to restrict creaduon of new periods as well.
    // const userCanEdit = hasAnyRole(accounts, allowedPageRoles.TimerPeriodEdit)
    const [showPeriodGraphic, setShowPeriodGraphic] = useState(true)

    function reverseString(str) {
        var splitString = str.split(""); 
        var reverseArray = splitString.reverse(); 
        var joinArray = reverseArray.join("");
        return joinArray; 
    }

    // get a schedule params from desired or reported
    function getCurrentScheduleData(device) {
        return isAwareDevice(device.serial_number) ? getCurrentAwareScheduleData(device) : getCurrentJEScheduleData(device)
    }

    function getCurrentAwareScheduleData(device) {
        const desired = (device.device_twin?.desired?.parameters ?? {})
        const res = {}
        const awareScheduleDefaults = getNewAwareDefaultScheduleData()
        for (let key of awareScheduleKeys) {
            let val = desired[key] ?? device.device_twin.reported.parameters[key]
            if (isUndefined(val)) {
                console.log("desired", desired)
                console.log("device.device_twin.reported.parameters", device.device_twin.reported.parameters)
                console.warn(`key ${key} not in either device.device_twin?.desired?.parameters or device.device_twin.reported.parameters. Using default '${awareScheduleDefaults[key]}'.`)
                val = awareScheduleDefaults[key]
            }
            res[key] = val
        }
        return res
    }

    // get a schedule params from desired or reported
    function getCurrentJEScheduleData(device) {
        const deviceScheduleDesiredParamsList = (device.device_twin?.desired?.parameters ?? {})
            .toEntries()
            .filter(o => o[0] >= 440 && o[0] <= 487)
        const deviceScheduleParamsList = device.device_twin.reported.parameters
            .toEntries()
            .filter(o => o[0] >= 440 && o[0] <= 487)
        for (const [desKey, desVal] of deviceScheduleDesiredParamsList) {
            if (!desVal) continue;
            const entry = deviceScheduleParamsList.find(e => e[0] === desKey)
            if (entry) entry[1] = desVal
        }
        return Object.fromEntries(deviceScheduleParamsList)
    }

    const constructScheduleListFromParams = (scheduleParams) => isAwareDevice(device.serial_number)
        ? constructAwareScheduleListFromParams(scheduleParams)
        : constructJEScheduleListFromParams(scheduleParams);

    /** if device has period list, use that, otherwise default */
    function getPeriodListFromDeviceOrDefault(device) {
        const currentScheduleData = getCurrentScheduleData(device)
        if (device.period_list && device.period_list.length > 0) {
            // On the active period we take schedule data from device reported/desired. It should be the same as
            // in period_list, but user might have changed it via service tool.
            return device.period_list.map(p => {
                return {
                    ...p,
                    schedule_list: constructScheduleListFromParams(
                        device.period_active_id === p.id ? currentScheduleData : p.schedule_data)
                }
            })
        }
        return [{
            id: "default",
            name: "DEFAULT_PERIOD_NAME",
            color: periodColors[0],
            schedule_data: currentScheduleData,
            schedule_list: constructScheduleListFromParams(currentScheduleData)
        }]
    }

    const [periodList, setPeriodList] = useState(getPeriodListFromDeviceOrDefault(device))
    const [updatedPeriodList, setUpdatedPeriodList] = useState([])

    // This is the hackiest of hacks but to set schedules to not in edit mode to communicate that save has happened we
    //  listen for toast that is added when period is saved successfully and set it then
    const {toastList} = useSelector(state => state.toast)
    useEffect(() => {
        if (toastList.some((t) => t.id === "saveTimerPeriodsByDeviceList" || t.id === "saveTimerPeriodsByGroupList")) {
            const periodListClone = cloneDeep(periodList)
            for (const p of periodListClone) {
                for (const s of p.schedule_list) {
                    s.inEditMode = false
                }
            }
            setPeriodList(periodListClone)
        }
    }, [toastList]);

    function anyHasMessages(list) {
        return list.some(item => item.messages && item.messages.length !== 0)
    }

    function getParamWrapperFromValue(value) {
        return {value: value, messages: []}
    }

    function constructAwareScheduleListFromParams(scheduleParams) {
        if (Object.keys(scheduleParams).length === 0) return []
        return [
            {
                 key: 1,
                 title: `${t('common:general.schedule')} 1`,
                 dayBits: reverseString(scheduleParams[awareParamNames.timer_schedule_1_days].toString(2).padStart(7, "0")),
                 timerStartMinutesSinceMidnight: parseInt(scheduleParams[awareParamNames.timer_schedule_1_start_time]),
                 timerDuration: parseInt(scheduleParams[awareParamNames.timer_schedule_1_duration]),
                 flowPercent: scheduleParams[awareParamNames.timer_schedule_1_flow],
                 temperatureCelcius: scheduleParams[awareParamNames.timer_schedule_1_temperature],
                 inEditMode: false,
                 isEnabled: scheduleParams[awareParamNames.timer_schedule_1_enabled],
                 barColor: scheduleBarColors[0],
                 isWaitingForUpdate: anyHasMessages(
                     [ // TODO: necessary?
                         getParamWrapperFromValue(scheduleParams[awareParamNames.timer_schedule_1_days]),
                         getParamWrapperFromValue(scheduleParams[awareParamNames.timer_schedule_1_start_time]),
                         getParamWrapperFromValue(scheduleParams[awareParamNames.timer_schedule_1_duration]),
                         getParamWrapperFromValue(scheduleParams[awareParamNames.timer_schedule_1_flow]),
                         getParamWrapperFromValue(scheduleParams[awareParamNames.timer_schedule_1_temperature]),
                         getParamWrapperFromValue(scheduleParams[awareParamNames.timer_schedule_1_enabled])
                     ]
                ),
                 messages: []
            },
            {
                key: 2,
                title: `${t('common:general.schedule')} 2`,
                dayBits: reverseString(scheduleParams[awareParamNames.timer_schedule_2_days].toString(2).padStart(7, "0")),
                timerStartMinutesSinceMidnight: parseInt(scheduleParams[awareParamNames.timer_schedule_2_start_time]),
                timerDuration: parseInt(scheduleParams[awareParamNames.timer_schedule_2_duration]),
                flowPercent: scheduleParams[awareParamNames.timer_schedule_2_flow],
                temperatureCelcius: scheduleParams[awareParamNames.timer_schedule_2_temperature],
                inEditMode: false,
                isEnabled: scheduleParams[awareParamNames.timer_schedule_2_enabled],
                barColor: scheduleBarColors[1],
                isWaitingForUpdate: anyHasMessages(
                    [ // TODO: necessary?
                        getParamWrapperFromValue(scheduleParams[awareParamNames.timer_schedule_2_days]),
                        getParamWrapperFromValue(scheduleParams[awareParamNames.timer_schedule_2_start_time]),
                        getParamWrapperFromValue(scheduleParams[awareParamNames.timer_schedule_2_duration]),
                        getParamWrapperFromValue(scheduleParams[awareParamNames.timer_schedule_2_flow]),
                        getParamWrapperFromValue(scheduleParams[awareParamNames.timer_schedule_2_temperature]),
                        getParamWrapperFromValue(scheduleParams[awareParamNames.timer_schedule_2_enabled])
                    ]
                ),
                messages: []
            }
        ]
    }

    /** @param {object} scheduleParams period schedule parameters object (eg: {440: "1", 441:"0", ...}) */
    function constructJEScheduleListFromParams(scheduleParams) {
        if (Object.keys(scheduleParams).length === 0) return []

        const ncFlagBits = scheduleParams[paramNames.timer_8_night_cool_flags] === undefined ? getParamWrapperFromValue("0000000000000000") : getParamWrapperFromValue(scheduleParams[paramNames.timer_8_night_cool_flags])
        const ncDayBits = getParamWrapperFromValue("1111111000000000")
        const ncStartTimerMinutesSinceMidnight = scheduleParams[paramNames.timer_8_night_cool_start_timer_minutes_since_midnight] === undefined ? getParamWrapperFromValue(0) : getParamWrapperFromValue(scheduleParams[paramNames.timer_8_night_cool_start_timer_minutes_since_midnight])
        const ncTimerDuration = getParamWrapperFromValue(scheduleParams[paramNames.timer_8_night_cool_duration_minutes])
        const ncFlowPer = getParamWrapperFromValue(scheduleParams[paramNames.timer_8_night_cool_flow_percent])
        const ncTempCelcius = getParamWrapperFromValue(scheduleParams[paramNames.timer_8_night_cool_temperature_c])
        const newScheduleList = [
            { // Always have night cool
                key: 0,
                title: t('settingspage:device_timers.modes.NightCool'),
                dayBits: ncDayBits.value,
                timerStartMinutesSinceMidnight: parseInt(ncStartTimerMinutesSinceMidnight.value),
                timerDuration: parseInt(ncTimerDuration.value),
                flowPercent: ncFlowPer.value,
                temperatureCelcius: ncTempCelcius.value,
                inEditMode: false,
                isEnabled: ncFlagBits.value[MODE_ENABLED_INDEX] === '1',
                barColor: scheduleBarColors[0],
                isWaitingForUpdate: anyHasMessages([ncDayBits, ncStartTimerMinutesSinceMidnight, ncTimerDuration, ncFlowPer, ncTempCelcius]),
                messages: []
            }
        ]

        // create listitem for each enabled schedule
        for (let i = 0; i < MAX_SCHEDULE_NUM; i++) {

            const flagBits = scheduleParams[TIMER_PARAMETERS_START + (i * 6) + 1] === undefined ? getParamWrapperFromValue("0000000000000000") : getParamWrapperFromValue(scheduleParams[TIMER_PARAMETERS_START + (i * 6) + 1])
            // noinspection EqualityComparisonWithCoercionJS
            if (flagBits.value[MODE_ENABLED_INDEX] != '1' && flagBits.value[MODE_SHOWN_INDEX] == '0') continue

            const dayBits = getParamWrapperFromValue(scheduleParams[TIMER_PARAMETERS_START + (i * 6)])
            const timerStartMinutesSinceMidnight = getParamWrapperFromValue(scheduleParams[TIMER_PARAMETERS_START + (i * 6) + 2])
            const timerDuration = getParamWrapperFromValue(scheduleParams[TIMER_PARAMETERS_START + (i * 6) + 3])
            const flowPercent = getParamWrapperFromValue(scheduleParams[TIMER_PARAMETERS_START + (i * 6) + 4])
            const tempCelcius = getParamWrapperFromValue(scheduleParams[TIMER_PARAMETERS_START + (i * 6) + 5])

            newScheduleList.push({
                key: i + 1,
                title: `${t('common:general.schedule')} ${i + 1}`,
                dayBits: dayBits.value,
                timerStartMinutesSinceMidnight: parseInt(timerStartMinutesSinceMidnight.value),
                timerDuration: parseInt(timerDuration.value),
                flowPercent: flowPercent.value,
                temperatureCelcius: tempCelcius.value,
                inEditMode: false,
                isEnabled: flagBits.value[MODE_ENABLED_INDEX] === '1',
                barColor: scheduleBarColors[i + 1],
                isWaitingForUpdate: anyHasMessages([timerStartMinutesSinceMidnight, timerDuration, dayBits, flowPercent, tempCelcius]),
                messages: []
            })
        }
        return newScheduleList
    }

    /** Picks an unused color for period */
    function pickNotUsedColor(periodListArg) {
        const usedColors = periodListArg.map(p => p.color) ?? []
        const remainingColors = difference(periodColors, usedColors)
        // if all is used, just pick at random, but this probably should not happen. Who makes 8 periods?
        if (remainingColors.length === 0) return periodColors[random(0, periodColors.length)]
        return remainingColors[0] // pick first unused color
    }

    const getParamDataFromScheduleList = (scheduleListRef) =>
        isAwareDevice(device.serial_number) ? getAwareParamDataFromScheduleList(scheduleListRef) : getJEParamDataFromScheduleList(scheduleListRef)

    const getAwareParamDataFromScheduleList = (scheduleListRef) => {
        let updated_parameters = {};
        
        scheduleListRef.forEach((scheduler) => {
            const itemKey = scheduler.key
            if (scheduler.dayBits) updated_parameters[awareParamNames[`timer_schedule_${itemKey}_days`]] = scheduler.dayBits != "0000000" ? parseInt(reverseString(scheduler.dayBits), 2) : 0
            if (scheduler.timerStartMinutesSinceMidnight) updated_parameters[awareParamNames[`timer_schedule_${itemKey}_start_time`]] = scheduler.timerStartMinutesSinceMidnight
            if (scheduler.timerDuration) updated_parameters[awareParamNames[`timer_schedule_${itemKey}_duration`]] = scheduler.timerDuration
            if (scheduler.flowPercent) updated_parameters[awareParamNames[`timer_schedule_${itemKey}_flow`]] = parseInt(scheduler.flowPercent)
            if (scheduler.temperatureCelcius) updated_parameters[awareParamNames[`timer_schedule_${itemKey}_temperature`]] = parseInt(scheduler.temperatureCelcius)
            if (scheduler.isEnabled !== undefined) updated_parameters[awareParamNames[`timer_schedule_${itemKey}_enabled`]] = scheduler.isEnabled
        });

        return updated_parameters;
    }

    const getJEParamDataFromScheduleList = (scheduleListRef) => {
        const updated_parameters = {}
        scheduleListRef.forEach((scheduler) => {
            const itemKey = scheduler.key
            if(itemKey === 0) {
                //Night cool
                //updated_parameters[deviceParams.timer_night_cool_days] = scheduler.dayBits
                if(scheduler.timerStartMinutesSinceMidnight) updated_parameters[deviceParams.timer_night_cool_start_timer] = scheduler.timerStartMinutesSinceMidnight
                if(scheduler.timerDuration) updated_parameters[deviceParams.timer_night_cool_duration_minutes] = scheduler.timerDuration
                if(scheduler.flowPercent) updated_parameters[deviceParams.timer_night_cool_flow_per] = scheduler.flowPercent
                if(scheduler.temperatureCelcius) updated_parameters[deviceParams.timer_night_cool_temp] = scheduler.temperatureCelcius
                if(scheduler.isEnabled !== undefined) {
                    const ncFlagBits = getParameterToShow(device, deviceParams.timer_night_cool_flags, t)
                    const ncFlagBitsWithEnabledUpdated = setCharAt(ncFlagBits.value, MODE_ENABLED_INDEX, scheduler.isEnabled ? '1' : '0')
                    updated_parameters[deviceParams.timer_night_cool_flags] = ncFlagBitsWithEnabledUpdated
                }
            }else{
                const flagBits = getParameterToShow(device, TIMER_PARAMETERS_START + ((itemKey-1) * 6) + 1, t)

                if(scheduler.dayBits) updated_parameters[TIMER_PARAMETERS_START + ((itemKey-1) * 6)] = scheduler.dayBits
                if(scheduler.timerStartMinutesSinceMidnight) updated_parameters[TIMER_PARAMETERS_START + ((itemKey-1) * 6) + 2] = scheduler.timerStartMinutesSinceMidnight
                if(scheduler.timerDuration)  updated_parameters[TIMER_PARAMETERS_START + ((itemKey-1) * 6) + 3] = scheduler.timerDuration
                if(scheduler.flowPercent) updated_parameters[TIMER_PARAMETERS_START + ((itemKey-1) * 6) + 4] = scheduler.flowPercent
                if(scheduler.temperatureCelcius) updated_parameters[TIMER_PARAMETERS_START + ((itemKey-1) * 6) + 5] = scheduler.temperatureCelcius
                if(scheduler.isEnabled !== undefined) {
                    const flagBitsWithEnabledUpdated = setCharAt(flagBits.value, MODE_ENABLED_INDEX, scheduler.isEnabled ? '1' : '0')
                    const flagBitsWithShownUpdated = setCharAt(flagBitsWithEnabledUpdated, MODE_SHOWN_INDEX, '1')
                    updated_parameters[TIMER_PARAMETERS_START + ((itemKey-1) * 6) + 1] = flagBitsWithShownUpdated
                }
                if(scheduler.removeSchedule){
                    const flagBitsWithEnabledUpdated = setCharAt(flagBits.value, MODE_ENABLED_INDEX, '0')
                    const flagBitsWithShownUpdated = setCharAt(flagBitsWithEnabledUpdated, MODE_SHOWN_INDEX, '0')
                    updated_parameters[TIMER_PARAMETERS_START + ((itemKey-1) * 6) + 1] = flagBitsWithShownUpdated
                }    
            }

        });
        return updated_parameters
    }

    /** validates parameters. returns errorMessages if any.
     * I didn't modify this for aware, I assume the restrictions are the same
     * @returns {[object]} */
    const validateScheduleParameters = (schedule) => {
        // the spec is the same for all schedules, so we just use the one from timer 1        
        const flowSpec = paramSpec[paramNames.timer_1_flow_percent]
        const tempSpec = paramSpec[paramNames.timer_1_temperature_c]
        const errorMessages = []
        if (isNaN(schedule.timerDuration) || isNaN(schedule.timerStartMinutesSinceMidnight)) {
            const errorMessage = {
                id: "timer_fields_are_invalid",
                type: "validation",
                text: "One or more Time fields are invalid"
            }
            errorMessages.push(errorMessage)
        }
        if (schedule.flowPercent < flowSpec.min) {
            const errorMessage = {
                id: "flow_less_than_min",
                type: "validation",
                text: t("settingspage:device_timers.errors.flow_less_than_min", {min: flowSpec.min})
            }
            errorMessages.push(errorMessage)
        }
        if (schedule.flowPercent > flowSpec.max) {
            const errorMessage = {
                id: "flow_more_than_max",
                type: "validation",
                text: t("settingspage:device_timers.errors.flow_more_than_max", {max: flowSpec.max})
            }
            errorMessages.push(errorMessage)
        }
        if (schedule.temperatureCelcius < tempSpec.min) {
            const errorMessage = {
                id: "temp_less_than_min",
                type: "validation",
                text: t("settingspage:device_timers.errors.temp_less_than_min", {min: tempSpec.min})
            }
            errorMessages.push(errorMessage)
        }
        if (schedule.temperatureCelcius > tempSpec.max) {
            const errorMessage = {
                id: "temp_more_than_max",
                type: "validation",
                text: t("settingspage:device_timers.errors.temp_more_than_max", {max: tempSpec.max})
            }
            errorMessages.push(errorMessage)
        }
        return errorMessages
    }


    /** find errors on period properties
     * {object} @param period
     * {[object]} @param periodListRef
     * @returns {object} map of period errors
     */
    const validatePeriodProps = (period, periodListRef) => {
        if (period.id === "default") return {}
        const periodErrorMap = {}
        const periodErrorList = periodListRef
            .filter(p => p.id !== "default" && p.id !== period.id && !validateMonthDayNoOverlap(period, p))
        if (periodErrorList.length > 0) {
            console.log("period is overlapping with one or more other periods", period)
            periodErrorMap[periodErrors.periodRangeOverlapError] = `${t('settingspage:device_timers.errors.period_cannot_overlap')}: ${periodErrorList.map(p => p.name).join(", ")}`
        }
        //validate if period name is unique ignore uppercase
        const periodNameErrorList = periodListRef
            .filter(p => p.id !== "default" && p.id !== period.id && p.name.toLowerCase() === period.name.toLowerCase())
        if (periodNameErrorList.length > 0) {
            periodErrorMap[periodErrors.periodNameError] = t('settingspage:device_timers.errors.period_name_must_be_unique')
        }

        return periodErrorMap
    }

    function anyPeriodErrors(periodListClone) {
        return Object.values(periodListClone.map(p => p.period_error_map)).some(errorMap => Object.entries(errorMap).length !== 0) ||
            periodListClone.some(p => p.schedule_list.some(s => s.messages.length !== 0))
    }

    function validateAndSave(saveForGroup) {
        // TODO: modify for Aware
        const periodListClone = cloneDeep(periodList)
        for (const period of periodListClone) {
            period.period_error_map = validatePeriodProps(period, periodList)
            for (const schedule of period.schedule_list) {
                schedule.messages = validateScheduleParameters(schedule)
            }
        }
        
        setPeriodList(periodListClone)
        if (anyPeriodErrors(periodListClone)) return
        console.log("=== Validation successful")
        const updatePeriodListToSave = updatedPeriodList.map(p => {
                    
            const periodToSave = {
                ...p,
                schedule_data: p.schedule_list === undefined ? {} : getParamDataFromScheduleList(p.schedule_list)
            }

            delete periodToSave['schedule_list']
            delete periodToSave['period_error_map']
            return periodToSave
        })

       save(updatePeriodListToSave, saveForGroup, settingsModalTypes.periods)
    }

    function deletePeriod(periodId, periodName) {
        setPeriodList(periodList.filter(p => p.id !== periodId))

        const updatedPeriodListCopy = cloneDeep(updatedPeriodList)
        let updatePeriodIdx = updatedPeriodListCopy.findIndex(p => p.id === periodId)
        if(updatePeriodIdx == -1) {
            updatedPeriodListCopy.push(
                {
                    id : periodId,
                    name : periodName
                }
            )
        }
        updatePeriodIdx = updatedPeriodListCopy.findIndex(p => p.id === periodId)
        const updatePeriod = updatedPeriodListCopy[updatePeriodIdx]
        if(updatePeriod.newly_created) {
            setUpdatedPeriodList(updatedPeriodList.filter(p => p.id !== periodId))
        }else{
            updatePeriod.deleted = true
            updatedPeriodListCopy[updatePeriodIdx] = updatePeriod
            setUpdatedPeriodList(updatedPeriodListCopy)
        }    
    }

    function editPeriod(period) {
        const periodListCopy = cloneDeep(periodList)
        const idx = periodListCopy.findIndex(p => p.id === period.id)
        const oldPeriod = periodListCopy[idx]       
        periodListCopy[idx] = period
        setPeriodList(periodListCopy)

        const updatedPeriodListCopy = cloneDeep(updatedPeriodList)
        let updatePeriodIdx = updatedPeriodListCopy.findIndex(p => p.id === period.id)

        if(updatePeriodIdx == -1) {
            updatedPeriodListCopy.push(
                {
                    id : period.id,
                    name : period.name,
                    schedule_list : []
                }
            )
        }
        updatePeriodIdx = updatedPeriodListCopy.findIndex(p => p.id === period.id)
        const updatePeriod = updatedPeriodListCopy[updatePeriodIdx]

        if(oldPeriod.name != period.name) updatePeriod.name = period.name
        if(oldPeriod.color != period.color) updatePeriod.color = period.color
        if(oldPeriod.from_month_day != period.from_month_day) updatePeriod.from_month_day = period.from_month_day
        if(oldPeriod.to_month_day != period.to_month_day) updatePeriod.to_month_day = period.to_month_day
        if(oldPeriod.system_mode != period.system_mode) updatePeriod.system_mode = period.system_mode
        updatedPeriodListCopy[updatePeriodIdx] = updatePeriod
        setUpdatedPeriodList(updatedPeriodListCopy)
    }

    function movePeriodUp(periodId) {
        const periodListCopy = cloneDeep(periodList)
        const idx = periodListCopy.findIndex(p => p.id === periodId)
        const removedPeriod = periodListCopy.splice(idx, 1)[0]
        periodListCopy.splice(idx - 1, 0, removedPeriod)
        setPeriodList(periodListCopy)
    }

    function movePeriodDown(periodId) {
        const periodListCopy = cloneDeep(periodList)
        const idx = periodListCopy.findIndex(p => p.id === periodId)
        const removedPeriod = periodListCopy.splice(idx, 1)[0]
        periodListCopy.splice(idx + 1, 0, removedPeriod)
        setPeriodList(periodListCopy)
    }

    function newPeriod() {
        const today = new Date()
        const month = today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1).toString()
        const newScheduleData = getNewDefaultScheduleData(device)
        const newPeriodObj = {
            id: createUUID(),
            name: t('settingspage:device_timers.add_period'),
            color: pickNotUsedColor(periodList),
            from_month_day: `${month}-01`,
            to_month_day: `${month}-${new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate()}`,
            system_mode: 4, // Auto
            schedule_data: newScheduleData,
            schedule_list: constructScheduleListFromParams(newScheduleData),
            newly_created: true,
            period_error_map: {}
        }
        const periodListCopy = cloneDeep(periodList)
        periodListCopy.splice(periodListCopy.length - 1, 0, newPeriodObj)
        setPeriodList(periodListCopy)
        updatedPeriodList.push(newPeriodObj)
        
        // Scroll to new period element and focus name text input
        setTimeout(() => {
            const element = document.querySelector(`h5 > input[value="${t('settingspage:device_timers.add_period')}"]`)
            if (element) {
                let boundingClientRect = element.getBoundingClientRect();
                window.scrollBy(0, boundingClientRect.top - 130)
                setTimeout(() => {
                    element.focus() // focus fucks with scroll, so that after we arrive
                }, 600)
            }
        }, 500)
    }

    const setPeriodScheduleList = (periodId, scheduleList) => {
        const periodListCopy = cloneDeep(periodList)
        const foundPeriod = periodListCopy.find(p => p.id === periodId)
        if (!foundPeriod) throw Error(`Could not find period with id ${periodId} from period list ${JSON.stringify(periodListCopy)}`)
        foundPeriod.schedule_list = scheduleList
        setPeriodList(periodListCopy)
    }

    const setUpdatedPeriodScheduleList = (periodId, periodName, itemKey, objKey, newValue) => {
        const periodListCopy = cloneDeep(updatedPeriodList)
        var foundPeriod = periodListCopy.find(p => p.id === periodId)
        if (!foundPeriod) {
            console.log(`Could not find period with id ${periodId} from period list ${JSON.stringify(periodListCopy)}`)
            foundPeriod = {
                id : periodId,
                name : periodName,
                schedule_list : []
            }
            periodListCopy.push(foundPeriod)
        }

        if(objKey == "addSchedule") {
            foundPeriod.schedule_list.push(newValue)
        }else{
            const schedule = foundPeriod.schedule_list.find(item => item.key === itemKey);

            if (schedule) {
                schedule[objKey] = newValue;
                foundPeriod.schedule_list[foundPeriod.schedule_list.findIndex(item => item.key === itemKey)] = schedule;
            } else {
                // Create a new schedule object and add it to the list
                const newSchedule = {
                    key: itemKey,
                    [objKey]: newValue
                };
                foundPeriod.schedule_list.push(newSchedule)
            }
        }
        setUpdatedPeriodList(periodListCopy)
    }

    return (
        <div className={className}>
            <DeviceConfigTopInformation className='mb-4' title={t('settingspage:device_timers.title')}
                            subTitle={t('settingspage:device_timers.description')}/>

            {!userCanEdit && <div
                className='color-danger-bright ms-3 mb-2'>{t('settingspage:device_timers.periods_missing_change_permissions_notice')}</div>}

            <div className='mb-3 cursor-pointer'
                 style={{border: '#EEEEEE 2px solid', borderRadius: '3px', background: '#fafafa', padding: "0.75rem"}}
                 onClick={() => setShowPeriodGraphic(!showPeriodGraphic)}>
                {!showPeriodGraphic && <div className='d-flex justify-content-between'>
                    <div>{t('settingspage:device_timers.show_period_overview')}</div>
                    <ChevronDoubleDown className={`m-1 mx-2`}/>
                </div>}
                {showPeriodGraphic && <DeviceTimerPeriodsChart className='cursor-pointer' periodList={periodList}/>}
            </div>

            {periodList.map((period, idx) =>
                <DeviceTimerPeriod key={period.id} period={period} index={idx}
                                   scheduleList={period.schedule_list} setScheduleList={setPeriodScheduleList} 
                                   setUpdatedScheduleList={setUpdatedPeriodScheduleList}
                                   periodListLenght={periodList.length} periodColor={period.color}
                                   deletePeriod={deletePeriod} editPeriod={editPeriod} movePeriodUp={movePeriodUp}
                                   movePeriodDown={movePeriodDown} periodActiveId={device.period_active_id}
                                   deviceIsSlave={device.slave} masterSerialNumber={device.master_serial_number}
                                   userCanEdit={userCanEdit} MAX_SCHEDULE_NUM={MAX_SCHEDULE_NUM}
                                   isAware={isAwareDevice(device.serial_number)}
                />)}

            <div className='p-2 mb-3 d-flex justify-content-end'>
                <Button variant='outline-secondary' disabled={device.slave} onClick={() => newPeriod()}>
                    <PlusLg className={'mb-1 me-1'}/>{t('settingspage:device_timers.add_period')}
                </Button>
            </div>

            {/*=== Button container*/}
            <div className='p-2 d-flex justify-content-end'>
                <Button variant='outline-secondary' disabled={device.slave} className='me-2'
                        onClick={() => validateAndSave(false)}>
                    {t('settingspage:save_device')}
                </Button>
                <Button variant='outline-secondary' disabled={device.slave} onClick={() => validateAndSave(true)}>
                        {t('settingspage:save_group')}
                </Button> 
            </div>
        </div>
    )
}

function getNewDefaultScheduleData(device) {
    return isAwareDevice(device.serial_number) ? getNewAwareDefaultScheduleData() : getNewJEDefaultScheduleData();
}

const awareScheduleKeys = ["timer_schedule_1_mode", "timer_schedule_1_days", "timer_schedule_1_start_time", "timer_schedule_1_duration", "timer_schedule_1_flow", "timer_schedule_1_temperature", "timer_schedule_1_enabled", "timer_schedule_2_mode", "timer_schedule_2_days", "timer_schedule_2_start_time", "timer_schedule_2_duration", "timer_schedule_2_flow", "timer_schedule_2_temperature","timer_schedule_2_enabled"]

// TODO: Adjust default values
function getNewAwareDefaultScheduleData() {
    return {
        "timer_schedule_1_days": 31,
        "timer_schedule_1_start_time": 420,
        "timer_schedule_1_duration": 720,
        "timer_schedule_1_flow": 30,
        "timer_schedule_1_mode": 1,
        "timer_schedule_1_temperature": 23,
        "timer_schedule_1_enabled": true,
        "timer_schedule_2_days": 31,
        "timer_schedule_2_start_time": 420,
        "timer_schedule_2_duration": 720,
        "timer_schedule_2_flow": 30,
        "timer_schedule_2_mode": 1,
        "timer_schedule_2_temperature": 23,
        "timer_schedule_2_enabled": false
    }
}

function getNewJEDefaultScheduleData() {
    return {
        "440": "1111100000000000",
        "441": "1000100000000000",
        "442": "420",
        "443": "720",
        "444": "30",
        "445": "19",
        "446": "1111100000000000",
        "447": "1000000000000000",
        "448": "420",
        "449": "600",
        "450": "30",
        "451": "19",
        "452": "0000011000000000",
        "453": "1000000000000000",
        "454": "420",
        "455": "600",
        "456": "30",
        "457": "19",
        "458": "0000011000000000",
        "459": "1000000000000000",
        "460": "420",
        "461": "600",
        "462": "30",
        "463": "19",
        "464": "1111111000000000",
        "465": "1000000000000000",
        "466": "420",
        "467": "600",
        "468": "30",
        "469": "19",
        "470": "1111111000000000",
        "471": "1000000000000000",
        "472": "420",
        "473": "600",
        "474": "30",
        "475": "19",
        "476": "1111111000000000",
        "477": "1000000000000000",
        "478": "420",
        "479": "600",
        "480": "30",
        "481": "19",
        "482": "1111111000000000",
        "483": "0100000000000000",
        "484": "0",
        "485": "360",
        "486": "100",
        "487": "14"
    };
}