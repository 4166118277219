import '../../../stylesheets/main.css'
import React, {useEffect, useState} from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Row from 'react-bootstrap/Row'
import {Link, NavLink, Route, useLocation} from 'react-router-dom'
import {GearFill, Grid, QuestionCircle, Wind} from 'react-bootstrap-icons'
import {useMsal} from "@azure/msal-react"
import {useDispatch, useSelector} from 'react-redux'
import logoAirlinq from '../../../images/white-airlinq.png'
import logoOnline from '../../../images/purple-online.png'
import Userpage from '../../../pages/userpage/UserPage.js'
import GroupsPage from '../../../pages/groups/GroupsPage.js'
import DeviceOverviewPage from '../../../pages/overview/DeviceOverviewPage.js'
import {allowedPageRoles, featureFlags, storageConstants, syslang} from '../../../helpers/constants.js'
import ProfileImageSelector from '../profileimageselector/ProfileImageSelector.js'
import {useTranslation} from 'react-i18next'
import FooterBar from '../footerbar/FooterBar.js'
import DeviceDashboardPage from '../../../pages/device/dashboard/DeviceDashboardPage.js'
import DeviceConfigurationsPage from '../../../pages/device/settings/DeviceConfigurationsPage.js'
import DeviceTechnicalPage from "../../../pages/device/technical/DeviceTechnicalPage"
import UpdateFirmwarePage from "../../../pages/firmware/UpdateFirmwarePage.js"
import ManageFirmwarePage from "../../../pages/firmware/ManageFirmwarePage.js"
import {getRoleAsText, hasAnyRole, isAdmin} from "../../../helpers/authHelper.js"
import HelpPage from "../../../pages/helppage/HelpPage"
import {resetLoading} from "../../../helpers/reduxstore/reducers/loadingReducer.js"
import {NavigateToDiv} from "./NavigateToDiv.js"
import NotificationsPage from "../../../pages/notifications/NotificationsPage"
import AdminOperationsPage from "../../../pages/adminoperation/AdminOperationsPage.js"
import V2HowToGuidePage from "../../../pages/helppage/v2HowToGuide/V2HowToGuidePage"
import DeviceParametersPage from "../../../pages/device/parameters/DeviceParametersPage.js"
import {NoPageFoundPage} from "../../../pages/NoPageFoundPage.js"
import {getMonitorLatestJeTelemetry} from "../../../api/api.js"
import moment from "moment"

/** Renders the navigation bar on top of the screen.
 * Populate user-related data in navbar dropdown from the global redux-store
 * IMPORTANT: Do not use useNavigate in here. For some reason it interferes with sentry, so navigation event are not sent. */
export default function NavigationBar({Routes}) {
    const dispatch = useDispatch()
    const {t} = useTranslation(['common', 'navigationbar'])
    const {user} = useSelector(state => state.user)
    const {instance, accounts} = useMsal()
    const [showErrorBanner, setShowErrorBanner] = useState(false)
    const location = useLocation();

    useEffect(() => {
        // There might be old loaders in redux, so we reset them here
        dispatch(resetLoading())

        getMonitorLatestJeTelemetry().then((res) => {
            const fiveMinutesAgo = moment().add(-5, "minutes")
            const latestTelemetryTime = moment(res.latestTelemetryTime)
            if (latestTelemetryTime < fiveMinutesAgo) {
                console.error("Latest telemetry was more than five minutes ago, showing error banner", JSON.stringify({
                    fiveMinutesAgo,
                    latestTelemetryTime
                }))
                setShowErrorBanner(true)
            }
        })

        // If REACT_APP_RESET_REDUX flag is set and latest git hash not same, reset redux
        if (process.env.REACT_APP_RESET_REDUX === "true" && window.localStorage[storageConstants.latestGitHash] !== process.env.REACT_APP_GIT_HASH) {
            dispatch({type: "RESET_REDUX", payload: {}})
            window.localStorage[storageConstants.latestGitHash] = process.env.REACT_APP_GIT_HASH
            window.setTimeout(() => {
                window.location.reload()
            }, 250)
        }
    }, [])

    async function logout() {
        const logoutRequest = {
            account: instance.getActiveAccount()
        }
        await instance.logoutRedirect(logoutRequest)
    }

    function getUserLangText(preferredLanguage) {
        if (!preferredLanguage) return ""

        const foundLang = syslang.find(e => e.id === preferredLanguage)
        if (foundLang) {
            return foundLang.option
        } else {
            return syslang.find(e => e.id === "en").option
        }
    }

    return (
        <div className='router-flex'>

            <Navbar expand="lg" collapseOnSelect variant="dark"
                    className='d-flex justify-content-between align-items-end bg-primary sticky-top px-4 px-xs-3 pt-0 pb-0'>

                <NavLink to='/' className='d-flex navbar-brand pb-2'>
                    <img src={logoAirlinq} alt="" style={{height: '20px'}}/>
                    <div className='bg-primarybg' style={{width: '1px', margin: '7px 14px '}}/>
                    <img src={logoOnline} alt="" style={{height: '15px', margin: 'auto'}}/>
                </NavLink>

                <div className='me-xs-0 navbar-brand d-flex p-0'>
                    {isAdmin(accounts) &&
                        <NavLink data-testid='Firmware' to='/firmware/update'
                                 className={`nav-link ${location.pathname.includes("/firmware/") ? 'active-navlink' : ''}`}>
                            <GearFill className='icon-styling color-secondary me-2'/>
                            <span
                                className={`d-none d-sm-block ${location.pathname.includes("/firmware/") ? 'color-secondary font-weight-500' : ''}`}>{t('navigationbar:firmware')}</span>
                        </NavLink>
                    }
                    <NavLink data-testid='Devices' to='/'
                             className={`nav-link ${location.pathname === "/" || location.pathname.includes("/device/") ? 'active-navlink' : ''}`}>
                        <Wind className='icon-styling color-secondary me-2'/>
                        <span
                            className={`d-none d-sm-block ${location.pathname === "/" || location.pathname.includes("/device/") ? 'color-secondary font-weight-500' : ''}`}>{t('navigationbar:devices')}</span>
                    </NavLink>
                    {hasAnyRole(accounts, allowedPageRoles.GroupsPage) &&
                        <NavLink data-testid='Groups' to='/groups'
                                 className={`nav-link ${location.pathname.includes("/groups") ? 'active-navlink' : ''}`}>
                            <Grid className='icon-styling color-secondary me-2'/>
                            <span
                                className={`d-none d-sm-block ${location.pathname.includes("/groups") ? 'color-secondary font-weight-500' : ''}`}>{t('navigationbar:groups')}</span>
                        </NavLink>
                    }
                    <NavLink data-testid='Help' to='/help'
                             className={`nav-link ${location.pathname.includes("/help") ? 'active-navlink' : ''}`}>
                        <QuestionCircle className='icon-styling color-secondary me-2'/>
                        <span
                            className={`d-none d-sm-block ${location.pathname.includes("/help") ? 'color-secondary font-weight-500' : ''}`}>{t('navigationbar:help')}</span>
                    </NavLink>
                </div>

                <Nav className='flex-row pb-0'>
                    <NavDropdown id='ProfileDropdown'
                                 title={
                                     <>
                                         <div className='icon-styling me-md-2' style={{height: '32px', width: '32px'}}>
                                             <ProfileImageSelector placeholderFontSize='sm' givenName={user.givenName}
                                                                   surname={user.surname}/>
                                         </div>
                                         <div className='d-none d-sm-inline-block text-responsive'
                                              style={{fontSize: '18px'}}
                                              data-testid='ProfileDropdown'>{t('navigationbar:profile')}</div>
                                     </>
                                 }
                    >
                        <div className='d-none d-sm-block'>
                            <Row className='text-nowrap d-block mt-3'>
                                <div className='d-inline-block ms-4 me-2 px-0 static-img-size align-middle'>
                                    <ProfileImageSelector placeholderFontSize='lg' givenName={user.givenName}
                                                          surname={user.surname}/>
                                </div>
                                <div className='d-inline-block me-4 ml-2 align-middle w-auto'>
                                    <h4 className='d-block'>{user.givenName ?? ""} {user.surname ?? ""}</h4>
                                    <span
                                        className='d-block'>{t('navigationbar:profiledropdown.mail')} {user.mail}</span>
                                    <span
                                        className='d-block'>{t('navigationbar:profiledropdown.role')} {t(`common:appRoles.${getRoleAsText(accounts)}`)}</span>
                                    <span
                                        className='d-block'>{t('navigationbar:profiledropdown.phone')} {user.mobilePhone}</span>
                                    <span
                                        className='d-block'>{t('navigationbar:profiledropdown.lang')} {getUserLangText(user.preferredLanguage)}</span>
                                </div>
                            </Row>
                            <hr/>
                        </div>
                        <NavDropdown.Item> {/*NavDropdown.Item wrapper makes dropdown close when clicking on item*/}
                            <NavigateToDiv dataTestId='Profile' to='/user'
                                           text={t('navigationbar:profiledropdown.profpage')}/>
                        </NavDropdown.Item>
                        {featureFlags.notifications && hasAnyRole(accounts, allowedPageRoles.NotificationsPage) &&
                            <NavDropdown.Item>
                                <NavigateToDiv dataTestId='Notifications' to='/notifications'
                                               text={t('navigationbar:profiledropdown.notifications')}/>
                            </NavDropdown.Item>
                        }
                        {isAdmin(accounts) &&
                            <NavDropdown.Item>
                                <NavigateToDiv to='/adminoperations/joblog' text='Admin Drift'/>
                            </NavDropdown.Item>
                        }
                        <Link data-testid='logout' className='dropdown-item ps-3' to=''
                              onClick={logout}>{t('navigationbar:profiledropdown.logout')}</Link>
                    </NavDropdown>
                </Nav>

            </Navbar>

           {showErrorBanner &&
                <div style={{background: 'yellow'}}
                     className='d-flex align-items-center justify-content-center'>
                    <span><b>{t('navigationbar:banner.production_issues')}</b></span>
                </div>
            }

            <div className='px-3 px-md-5 d-flex flex-column bg-primarybg' style={{flex: 1}}>
                <Routes>
                    <Route path="/" element={<DeviceOverviewPage/>}/>
                    <Route path="/firmware/update" element={<UpdateFirmwarePage/>}/>
                    <Route path="/firmware/manage" element={<ManageFirmwarePage/>}/>
                    <Route path="/device/:deviceSerialNumber/dashboard" element={<DeviceDashboardPage/>}/>
                    <Route path="/device/:deviceSerialNumber/configurations/:subPage"
                           element={<DeviceConfigurationsPage/>}/>
                    <Route path="/device/:deviceSerialNumber/technical" element={<DeviceTechnicalPage/>}/>
                    <Route path="/device/:deviceSerialNumber/parameters" element={<DeviceParametersPage/>}/>
                    <Route path="user" element={<Userpage/>}/>
                    <Route path="notifications" element={<NotificationsPage/>}/>
                    <Route path="help" element={<HelpPage/>}/>
                    <Route path="help/v2guide" element={<V2HowToGuidePage/>}/>
                    <Route path="groups" element={<GroupsPage/>}/>
                    <Route path="adminoperations/:subPage" element={<AdminOperationsPage/>}/>
                    <Route path="*" element={<NoPageFoundPage/>}/>
                </Routes>
            </div>

            <FooterBar/>
        </div>
    )
}

