// noinspection EqualityComparisonWithCoercionJS

import React, {useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import Checkbox from '../../../components/shared/checkbox/CheckBox.js'
import TelemetryChartsChartSelector from './chartselectordropdown/TelemetryChartsChartSelector.js'
import ModalTopText from '../../../components/shared/modaltoptext/ModalTopText.js'
import {uniq, uniqueId} from 'lodash'
import {roleConstants} from '../../../helpers/constants.js'
import PeriodSelector from './periodselector/PeriodSelector.js'
import {paramNames} from "../../parameters/parameterSpec.js";
import PopOverDialog from "../../shared/popoverdialog/PopOverDialog.js";
import {ArrowClockwise, FilePdfFill, FileSpreadsheetFill} from "react-bootstrap-icons";
import {Button} from "react-bootstrap";
import {hasAnyRole, isAdmin, isTechnicalAdvanced} from "../../../helpers/authHelper.js";
import {useMsal} from "@azure/msal-react";
import {isAwareDevice, pullDevicesFromHierarchy, roundToFractionDigits} from "../../../helpers/helperfunctions.js";
import {
    exportAllCSV,
    exportCSV,
    exportPDF,
    getAwareGraphTooltipKeys,
    getJEGraphTooltipKeys,
    isGraphValidForDevice,
    NowPlusDays
} from "./telemetryChartsHelper.js";
import TelemetryChartsDeviceSelector from './chartselectordropdown/TelemetryChartsDeviceSelector.js'
import {useSelector} from 'react-redux'
import {getTelemetry} from '../../../api/api.js'
import {TelemetryChartsGraph} from './TelemetryChartsGraph.js'
import {LoadSpinner} from '../../shared/loader/LoadSpinner.js'
import {awareTelemNames} from "../../parameters/awareTelemSpec.js";
import moment from "moment/moment.js";


/** Renders Telemetry charts element
 * @param {object} device the current device
 * @param {string} className classes to wrap the component
 * @param {string[]} graphSelections
 * @param {string[]} graphOptionList function to get graph options to show in dropdown
 * @param {string[]} defaultGraphList array of default graphs to show if there are no valid graphs selected for device
 * @param {function(Object)} setGraphSelections
 */
export default function TelemetryCharts({
                                            device, className, graphSelections,
                                            graphOptionList, defaultGraphList, setGraphSelections
                                        }) {
    const {t} = useTranslation(['common', 'dashboard', 'telemetry'])
    const {accounts} = useMsal()
    const {allowedGroups} = useSelector(state => state.group)
    const [deviceSelections, setDeviceSelections] = useState([device.serial_number])
    const [fetchedTelemetryList, setFetchedTelemetryList] = useState([])
    const [fetchInProgress, setFetchInProgress] = useState(true)
    const [operatingOnly, setOperatingOnly] = useState(false)

    const [showDatesFilter, setShowDatesFilter] = useState(false)
    const [periodFilter, setPeriodFilter] = useState({
        discretePeriodSelected: DatePeriods.Day,
        continuousStartDate: "",
        continuousEndDate: "",
    })

    const [start, setStart] = useState(NowPlusDays(-1).toISOString())
    const [end, setEnd] = useState(new Date().toISOString())

    const telemetryTimeoutRef = useRef(null)
    const [graphsToShow, setGraphsToShow] = useState([])

    useEffect(() => {
        // if graphSelections is null, user has never chosen graphs, se we show default graphs
        if (!graphSelections) {
            setGraphsToShow(defaultGraphList)
            return

        }
        // if graphSelections is not null and is empty, it means user has removed the graphs, so we show no graphs
        if (graphSelections.length === 0) {
            setGraphsToShow([])
            return
        }

        // If there are no valid graphs selected for the current device, return default graphs for the device
        let validGraphs = graphSelections.filter(graphType => graphType === "New" || isGraphValidForDevice(device, graphType));
        if (validGraphs.length === 0) {
            setGraphsToShow(defaultGraphList)
        } else {
            setGraphsToShow(validGraphs)
        }
    }, [graphSelections])

    useEffect(() => {
        if (telemetryTimeoutRef.current !== null)
            window.clearTimeout(telemetryTimeoutRef.current)

        if (fetchedTelemetryList.length !== 0 && (deviceSelections.includes("New") || graphsToShow.includes("New"))) return;
        telemetryTimeoutRef.current = setTimeout(() => {
            fetchTelemetryData().then()
            telemetryTimeoutRef.current = null
        }, 500)
    }, [periodFilter, graphsToShow, deviceSelections])

    const fetchTelemetryData = async () => {
        // Calculate start and end time
        let newStart, newEnd;
        if (showDatesFilter) {
            newStart = moment(periodFilter.continuousStartDate).startOf("day").toISOString()
            if (periodFilter.continuousEndDate) {
                newEnd = moment(periodFilter.continuousEndDate).startOf("day").toISOString()
            } else {
                newEnd = new Date().toISOString()
            }
        } else {
            let min = new Date()
            switch (periodFilter.discretePeriodSelected) {
                case DatePeriods.Day:
                    min.setDate(min.getDate() - 1)
                    break;
                case DatePeriods.Week:
                    min.setDate(min.getDate() - 7)
                    break;
                case DatePeriods.Month:
                    min.setMonth(min.getMonth() - 1)
                    break;
                default:
                    break;
            }
            newStart = min.toISOString()
            newEnd = new Date().toISOString()
        }

        if (!newStart || !newEnd) {
            console.warn("Tried fetching telemetry but start or end was falsy", {newStart, newEnd})
            return
        }

        setFetchInProgress(true)
        const graphSelectionList = uniq([...uniq(graphsToShow.map(getGraphTooltipKeys).flat()), paramNames.started_by_NAMEDUPLICATE_OF_96])
        const telemList = []
        for (const deviceSerial of deviceSelections) {
            // Airflow graph does not look good with few datapoints and support does not want a stepgraph, so for
            //  airflow graph only we get non-changed points, so it does not look silly
            let discardNotChanged = !(graphSelectionList.includes(1031) || graphSelectionList.includes(awareTelemNames.rqf));
            let data = await getTelemetry(graphSelectionList, deviceSerial, newStart, newEnd, discardNotChanged)

            // TODO: remove this when temperature decimals are rounded in backend
            // Support wants temperature decimals to be rounded to 1, so for now we do that here
            if (data?.dataList[0]?.t_ai) {
                data.dataList.forEach(tel => {
                    tel.t_ai = roundToFractionDigits(tel.t_ai, 1)
                    tel.t_ar = roundToFractionDigits(tel.t_ar, 1)
                    tel.t_ao = roundToFractionDigits(tel.t_ao, 1)
                    tel.t_ae = roundToFractionDigits(tel.t_ae, 1)
                })
            }

            telemList.push(data)
        }
        setFetchedTelemetryList(telemList)
        setStart(newStart)
        setEnd(newEnd)
        setFetchInProgress(false)
    }

    function getGraphTooltipKeys(graphType) {
        return isAwareDevice(device.serial_number) ? getAwareGraphTooltipKeys(graphType) : getJEGraphTooltipKeys(graphType, device)
    }

    return (
        <div className={className}>
            <div className="mb-2 w-100 d-flex flex-row flex-wrap" style={{gap: "20px"}}>
                <TelemetryChartsChartSelector
                    className="d-inline-block"
                    graphOptions={graphOptionList}
                    setGraphSelections={setGraphSelections}
                    graphSelections={graphsToShow ?? []}
                />
                {(isAdmin(accounts) || isTechnicalAdvanced(accounts)) &&
                    <TelemetryChartsDeviceSelector
                        className="d-inline-block" deviceSelections={deviceSelections}
                        setDeviceSelections={setDeviceSelections}
                        dropdownOptions={uniq(pullDevicesFromHierarchy(allowedGroups).map(d => ({
                            serial_number: d.serial_number,
                            name: d.name
                        }))) ?? []}
                    />
                }
                <PeriodSelector
                    className="d-md-flex ms-md-3" showDatesFilter={showDatesFilter}
                    setShowDatesFilter={setShowDatesFilter} periodFilter={periodFilter}
                    setPeriodFilter={setPeriodFilter}
                />
                {device?.device_twin?.reported?.applicationVersion >= '6243' &&
                    <div className="align-self-center ms-md-0 ms-sm-0 ms-xs-0 d-flex">
                        <Checkbox controlId="operationSelector" label={t('dashboard:operation_only_label')}
                                  onChange={e => setOperatingOnly(e.target.checked)}/>
                        <PopOverDialog popOverTitel={t('dashboard:operation_only_label')}
                                       popOverContent={t('dashboard:infomodals.operation_only')}
                                       trigger={['hover', 'touch']} contentAsHtml={true} size={'lg'}/>
                    </div>
                }

                <Button onClick={() => fetchTelemetryData()}
                        className="" variant='outline-secondary'>
                    <ArrowClockwise/> {t('common:refresh')}
                </Button>

                <div className="ms-lg-auto d-flex">
                    {hasAnyRole(accounts, [roleConstants.Admin, roleConstants.Technical, roleConstants.TechnicalBasic]) &&
                        <Button className="" variant='outline-secondary'
                                onClick={() => exportCSV(uniq(graphsToShow.map(getGraphTooltipKeys).flat()), fetchedTelemetryList, t)}>
                            <FileSpreadsheetFill className="me-1"/>{t('dashboard:export.csv.text')}
                        </Button>
                    }
                    {document.location.href.includes("technical") && hasAnyRole(accounts, [roleConstants.Admin, roleConstants.Technical, roleConstants.TechnicalBasic]) &&
                        <Button onClick={() => exportAllCSV(device, start, end)}
                                className="ms-2" variant='outline-secondary'>
                            <FileSpreadsheetFill className="me-1"/>{t("dashboard:export.csv_all.text")}
                        </Button>
                    }
                    <Button onClick={() => exportPDF(device.name, device.serial_number)}
                            className="ms-2" variant='outline-secondary'>
                        <FilePdfFill className="me-1"/>{t('dashboard:export.pdf.text')}
                    </Button>
                </div>
            </div>

            {graphsToShow.length === 0 &&
                <ModalTopText body={t('dashboard:graphSelections.modal_top_text')} className=" mb-4"/>}
            {fetchInProgress &&
                <LoadSpinner style={{marginTop: "100px", marginBottom: "100px"}} height="60px" width="60px"/>}
            {!fetchInProgress &&
                <div id="graph-container">
                    {graphsToShow.filter(graphType => graphType !== "New" && graphOptionList.includes(graphType)).map(graph =>
                        <div className="w-100 bg-primarybg-dim rounded p-2 mb-2"
                             key={`${graph}_${uniqueId()}`}>
                            {fetchedTelemetryList.map(telem =>
                                <React.Fragment key={`${graph}_${uniqueId()}_${telem.id}`}>
                                    <TelemetryChartsGraph
                                        graph={graph} getGraphTooltipKeys={getGraphTooltipKeys}
                                        telemObj={telem} start={start} end={end} operatingOnly={operatingOnly}
                                        showSerialInTitle={fetchedTelemetryList.length > 1}
                                    />
                                </React.Fragment>)}
                        </div>
                    )}
                </div>
            }
        </div>
    )
}

export const DatePeriods = {
    Day: "Day",
    Week: "Week",
    Month: "Month"
}
