import {toastTypes} from '../../../../helpers/constants.js'
import React, {useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {Button} from "react-bootstrap";
import InputGroupWithMessages from "../../../shared/inputgroupwithmessages/InputGroupWithMessages.js";
import ComboBoxWithMessages from "../../../shared/comboboxwithmessages/ComboBoxWithMessages.js";
import {getParameterToShow, updateFieldIfChanged} from "../../../../helpers/settingsHelper.js";
import {addParameterIfChanged, validateParameter} from "../../../../helpers/awareSettingsHelper.js";
import {awareParamNames} from "../../../parameters/awareParamSpec.js";
import {cloneDeep} from "lodash";
import PopOverDialog from "../../../shared/popoverdialog/PopOverDialog.js";
import {addToast} from "../../../../helpers/reduxstore/reducers/toastReducer.js";
import {useDispatch} from "react-redux";
import {useMsal} from "@azure/msal-react";
import {isBasic} from "../../../../helpers/authHelper.js";
import {CheckCircle, ExclamationTriangle} from "react-bootstrap-icons";
import DeviceConfigTopInformation from "../../../shared/topinformation/DeviceConfigTopInformation.js";
import SettingsSubHeader from "../../settingscomponents/SettingsSubHeader.js";

/** Displays the configuration page for filter settings
 * @param {object} device device object
 * @param {string} className classes for the component wrapper
 * @param {function} save save function to trigger displaying group selector or confirmation (for single device) */
export default function AwareFilterSettings({device, className, save}) {
    const {t} = useTranslation(['settingspage', 'common', 'telemetry'])
    const dispatch = useDispatch()
    const {accounts} = useMsal()
    const [oldDeviceState, setOldDeviceState] = useState(cloneDeep(device))

    const system_alarms = device?.latest_telemetry?.system_alarms;
    const system_warnings = device?.latest_telemetry?.system_warnings;

    const filter_alarm_exists = system_alarms === undefined ? false : system_alarms.some(alarm => alarm.message.toLowerCase().includes("filter"));
    const filter_warning_exists = system_warnings === undefined ? false : system_warnings.some(warning => warning.message.toLowerCase().includes("filter"));

    const getFilterLatestStatus = () => {
        if (filter_alarm_exists) return "ALARM";
        if (filter_warning_exists) return "WARNING"; // filter change needed
        return "GOOD";
    }

    // Settings update state
    const [filterLatestStatus, setFilterLatestStatus] = useState(getFilterLatestStatus(device))
    const [filterTestMode, setFilterTestMode] = useState(getParameterToShow(device, awareParamNames.filter_test_mode, t))
    const [filterMaxLifeTimeWarning, setFilterMaxLifeTimeWarning] = useState(getParameterToShow(device, awareParamNames.filter_test_max_lifetime_warning_period, t))
    const [filterLifeSpanWarning, setFilterLifeSpanWarning] = useState(getParameterToShow(device, awareParamNames.filter_test_hours_warning, t))
    const [filterMaxLifeTimeAlert, setFilterMaxLifeTimeAlert] = useState(getParameterToShow(device, awareParamNames.filter_test_max_lifetime_alarm, t))
    const [filterLifeSpanAlert, setFilterLifeSpanAlert] = useState(getParameterToShow(device, awareParamNames.filter_test_hours_alarm, t))

    useEffect(() => {
        // When device is updated after write, update parameters to show / hide messages
        setFilterLatestStatus(getFilterLatestStatus(device))
        updateFieldIfChanged(setFilterTestMode, oldDeviceState, device, awareParamNames.filter_test_mode, t)
        updateFieldIfChanged(setFilterMaxLifeTimeWarning, oldDeviceState, device, awareParamNames.filter_test_max_lifetime_warning_period, t)
        updateFieldIfChanged(setFilterMaxLifeTimeAlert, oldDeviceState, device, awareParamNames.filter_test_max_lifetime_alarm, t)
        updateFieldIfChanged(setFilterLifeSpanWarning, oldDeviceState, device, awareParamNames.filter_test_hours_warning, t)
        updateFieldIfChanged(setFilterLifeSpanAlert, oldDeviceState, device, awareParamNames.filter_test_hours_alarm, t)
        setOldDeviceState(cloneDeep(device))
    }, [device])

    /**
     * validates parameters. Adds messages to viewmodels if fail. returns true if all is validated, otherwise false.
     * @returns {boolean}
     */
    const validateParameters = () => {
        let validatedList = []
        validatedList.push(validateParameter(awareParamNames.filter_test_mode, filterTestMode, setFilterTestMode, t))
        validatedList.push(validateParameter(awareParamNames.filter_test_max_lifetime_warning_period, filterMaxLifeTimeWarning, setFilterMaxLifeTimeWarning, t))
        validatedList.push(validateParameter(awareParamNames.filter_test_hours_warning, filterLifeSpanWarning, setFilterLifeSpanWarning, t))
        validatedList.push(validateParameter(awareParamNames.filter_test_max_lifetime_alarm, filterMaxLifeTimeAlert, setFilterMaxLifeTimeAlert, t))
        validatedList.push(validateParameter(awareParamNames.filter_test_hours_alarm, filterLifeSpanAlert, setFilterLifeSpanAlert, t))
        return validatedList.every(v => v)
    }

    const getValuesToSave = () => {
        const updated_parameters = {}
        addParameterIfChanged(updated_parameters, awareParamNames.filter_test_mode, filterTestMode.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.filter_test_max_lifetime_warning_period, filterMaxLifeTimeWarning.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.filter_test_hours_warning, filterLifeSpanWarning.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.filter_test_max_lifetime_alarm, filterMaxLifeTimeAlert.value, device)
        addParameterIfChanged(updated_parameters, awareParamNames.filter_test_hours_alarm, filterLifeSpanAlert.value, device)
        console.log(updated_parameters)
        return updated_parameters
    }

    function saveIfValidated(saveForGroup) {
        if (!validateParameters()) return

        let valuesToSave = getValuesToSave();
        if (Object.keys(valuesToSave).length === 0) {
            dispatch(addToast({
                id: "FilterSettingsInfoToast",
                type: toastTypes.info,
                title: "No changes",
                body: "No changes to parameters",
                timeSeconds: 3
            }))
            return
        }
        save(valuesToSave, saveForGroup);
    }

    const prepareResetData = () => {
        return {
            ["filter_reset_status"]: true
        }
    }

    return (
        <div className={className}>
            <DeviceConfigTopInformation title={t('settingspage:filter_settings.title')}
                                        subTitle={t('settingspage:filter_settings.description')}/>

            {/* === Alarm or warning Log List*/}
            <SettingsSubHeader>{t('settingspage:filter_settings.alarm_list_title')}</SettingsSubHeader>
            <div className='m-2'>
                <span className='m-sm-2 ms-2'>{t('settingspage:filter_settings.alarm_list_description')}</span>
                <div className='flex-grow-1 border border-dim rounded-3 m-2'>
                    <div style={{fontSize: '12px', maxHeight: "450px"}} className='overflow-auto'>
                        {filterLatestStatus === "ALARM" &&
                            <div className='bg-danger-dim d-flex p-3 align-items-center ps-md-5'>
                                <ExclamationTriangle className="color-danger-bright" size={'3rem'}/>
                                <div className='d-block ms-3'>
                                    <p className='mb-1'><span
                                        className='fw-bold'>{t("settingspage:filter_log.activated_by")}:</span> {t("telemetry:SysAlarm_FilterAlarm")}
                                    </p>
                                </div>
                            </div>
                        }
                        {filterLatestStatus === "WARNING" &&
                            <div className='bg-warning-dim d-flex p-3 align-items-center ps-md-5'>
                                <ExclamationTriangle className="color-warning" size={'3rem'}/>
                                <div className='d-block ms-3'>
                                    <p className='mb-1'><span
                                        className='fw-bold'>{t("settingspage:filter_log.activated_by")}:</span> {t("telemetry:SysCon_FilterChangeNeeded")}
                                    </p>
                                </div>
                            </div>
                        }
                        {filterLatestStatus === "GOOD" &&
                            <div className='bg-success-dim d-flex p-3 align-items-center ps-md-5'>
                                <CheckCircle className="color-success" size={'3rem'}/>
                                <div className='d-block ms-3'>
                                    <p className='mb-1'><span
                                        className='fw-bold'>{t("settingspage:filter_log.latest_status")}:</span> {t("settingspage:filter_log.no_problems")}
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

            {/* === reset buttons */}
            <div className='d-flex d-xs-block p-2 w-100 justify-content-end'>
                <Button variant='outline-secondary' onClick={() => save(prepareResetData(), false)}
                        className='me-2'>
                    {t('settingspage:reset_device')}</Button>
                <Button variant='outline-secondary' onClick={() => save(prepareResetData(), true)}>
                    {t('settingspage:reset_group')}</Button>
            </div>


            {/*Filter Settings*/}
            {!isBasic(accounts) &&
                <div className={'p-2 my-2 w-100 me-16px me-xs-0'}>
                    <SettingsSubHeader>{t('settingspage:filter_settings.subfilter_title')}</SettingsSubHeader>
                    <div className="mt-sm-3 d-flex flex-wrap justify-content-xs-center">

                        {/*Only visible below 1518px, that is where the grouped fields below jump line, so we group
                                  filter test mode and filter flow alarm fields as well, so it doesn't look silly.
                                  Might have been better with a css grid...*/}
                        <div className='d-1518px-none'>
                            <div className='w-300px'>
                                <ComboBoxWithMessages
                                    className='p-4 m-auto'
                                    label={t('settingspage:parameters.filter_test_mode')}
                                    inputValue={(e) => setFilterTestMode({value: e.target.value})}
                                    value={filterTestMode.value}
                                    options={[
                                        {
                                            id: 0,
                                            option: t('settingspage:filter_settings.filter_test_mode_options.off')
                                        },
                                        {
                                            id: 1,
                                            option: t('settingspage:filter_settings.filter_test_mode_options.timer')
                                        },
                                        {
                                            id: 2,
                                            option: t('settingspage:filter_settings.filter_test_mode_options.lifetime')
                                        },
                                        {
                                            id: 3,
                                            option: t('settingspage:filter_settings.filter_test_mode_options.timer_and_lifetime')
                                        }
                                    ]}
                                    messages={filterTestMode.messages}
                                    infoelement={<PopOverDialog
                                        popOverTitel={t('settingspage:parameters.filter_test_mode')}
                                        popOverContent={t('settingspage:infomodals.filter_test_mode')}
                                        trigger={['hover', 'touch']}
                                        contentAsHtml={true}/>}
                                />
                            </div>
                        </div>

                        <div className='w-300px d-none d-1518px-block'>
                            <ComboBoxWithMessages
                                className='p-4 m-auto'
                                label={t('settingspage:parameters.filter_test_mode')}
                                inputValue={(e) => setFilterTestMode({value: e.target.value})}
                                value={filterTestMode.value}
                                options={[
                                    {
                                        id: 0,
                                        option: t('settingspage:filter_settings.filter_test_mode_options.off')
                                    },
                                    {
                                        id: 1,
                                        option: t('settingspage:filter_settings.filter_test_mode_options.timer')
                                    },
                                    {
                                        id: 2,
                                        option: t('settingspage:filter_settings.filter_test_mode_options.lifetime')
                                    },
                                    {
                                        id: 3,
                                        option: t('settingspage:filter_settings.filter_test_mode_options.timer_and_lifetime')
                                    }
                                ]}
                                messages={filterTestMode.messages}
                                infoelement={<PopOverDialog
                                    popOverTitel={t('settingspage:parameters.filter_test_mode')}
                                    popOverContent={t('settingspage:infomodals.filter_test_mode')}
                                    trigger={['hover', 'touch']}
                                    contentAsHtml={true}/>}
                            />
                        </div>

                        <div> {/* warning and alart should be grouped */}
                            <div className='w-300px'>
                                <InputGroupWithMessages
                                    label={t('settingspage:parameters.filter_max_life_time_warning')}
                                    className='p-4 m-auto'
                                    value={filterMaxLifeTimeWarning.value}
                                    unit={t('common:units.months')}
                                    inputValue={(e) => setFilterMaxLifeTimeWarning({value: e.target.value})}
                                    placeholder={t('common:units.months')}
                                    messages={filterMaxLifeTimeWarning.messages}
                                    type='number'
                                    infoelement={<PopOverDialog
                                        popOverTitel={t('settingspage:parameters.filter_max_life_time_warning')}
                                        popOverContent={t('settingspage:infomodals.filter_max_life_time_warning')}
                                        trigger={['hover', 'touch']}
                                        contentAsHtml={true}/>}
                                />
                            </div>
                            <div className='w-300px'>
                                <InputGroupWithMessages
                                    label={t('settingspage:parameters.filter_max_life_time_alarm')}
                                    className='p-4 m-auto'
                                    value={filterMaxLifeTimeAlert.value}
                                    unit={t('common:units.months')}
                                    inputValue={(e) => setFilterMaxLifeTimeAlert({value: e.target.value})}
                                    placeholder={t('common:units.months')}
                                    messages={filterMaxLifeTimeAlert.messages}
                                    type='number'
                                    infoelement={<PopOverDialog
                                        popOverTitel={t('settingspage:parameters.filter_max_life_time_alarm')}
                                        popOverContent={t('settingspage:infomodals.filter_max_life_time_alarm')}
                                        trigger={['hover', 'touch']}
                                        contentAsHtml={true}/>}
                                />
                            </div>
                        </div>

                        <div> {/* warning and alart should be grouped */}
                            <div className='w-300px'>
                                <InputGroupWithMessages
                                    label={t('settingspage:parameters.filter_life_span_warning')}
                                    className='p-4 m-auto'
                                    value={filterLifeSpanWarning.value}
                                    unit={t('common:units.hours')}
                                    inputValue={(e) => setFilterLifeSpanWarning({value: e.target.value})}
                                    placeholder={t('common:units.hours')}
                                    messages={filterLifeSpanWarning.messages}
                                    type='number'
                                    infoelement={<PopOverDialog
                                        popOverTitel={t('settingspage:parameters.filter_life_span_warning')}
                                        popOverContent={t('settingspage:infomodals.filter_life_span_warning')}
                                        trigger={['hover', 'touch']}
                                        contentAsHtml={true}/>}
                                />
                            </div>
                            <div className='w-300px'>
                                <InputGroupWithMessages
                                    label={t('settingspage:parameters.filter_life_span_alarm')}
                                    className='p-4 m-auto'
                                    value={filterLifeSpanAlert.value}
                                    unit={t('common:units.hours')}
                                    inputValue={(e) => setFilterLifeSpanAlert({value: e.target.value})}
                                    placeholder={t('common:units.hours')}
                                    messages={filterLifeSpanAlert.messages}
                                    type='number'
                                    infoelement={<PopOverDialog
                                        popOverTitel={t('settingspage:parameters.filter_life_span_alarm')}
                                        popOverContent={t('settingspage:infomodals.filter_life_span_alarm')}
                                        trigger={['hover', 'touch']}
                                        contentAsHtml={true}/>}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }

            {/*=== Button container*/}
            {!isBasic(accounts) &&
                <div className='d-inline-block float-end p-2'>
                    <Button variant='outline-secondary' onClick={() => saveIfValidated(false)}
                            className='me-2'>
                        {t('settingspage:save_device')}</Button>
                    <Button variant='outline-secondary' onClick={() => saveIfValidated(true)}>
                        {t('settingspage:save_group')}</Button>
                </div>
            }
        </div>
    )
}