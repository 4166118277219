// For some explanation for all these codemappings, see
// https://dev.azure.com/airmasteras/AirlinqOnline/_wiki/wikis/AirlinqOnline.wiki/97/Indstillinger-mappings
export const deviceParams = {

    // === DASHBOARD TELEMETRY
    outlet: 1007,
    room: 1000,
    inlet: 1001,
    outside: 1002,
    outside_ventilation: 1006,
    exhaust_ventilation: 1007,
    system_mode: 1034,
    requested_temperature: 1030,
    airflow: 1031,
    comfort_heater: 1015,
    pre_heater: 1019,
    started_by: 1078,
    started_by_param: 96,
    main_damper: 1044,
    bypass_damper: 1016,
    co2: 1009,
    voc: 1074,
    humidity: 1033,
    cooling_unit_power_consumption: 1065,
    air_handling_unit_power_consumption: 1050,
    supply_flow: 1023,
    extraction_flow: 1024,
    remaining_service_life_hours: 1055,
    temperature_evaporator_in: 1056,
    temperature_evaporator_out: 1057,
    temperature_hotgas: 1058,
    fan_supply_rpm: 1027,
    fan_extraction_rpm: 1028,

    flow_mode: 239, //{0=Volt Direct, 1=Flow Control, 2=Volt Control}
    flow_control_supply: 1023, // relevant if flow_mode is 1=Flow Control
    flow_control_extraction: 1024, // relevant if flow_mode is 1=Flow Control


    // === DEVICE SETTINGS START
    // Device operation
    settings_airflow: 100,
    panel_start_timeout: 352,
    panel_off_timeout: 353,
    override_time: 50,

    // Device setops
    airflow_control_limit_min: 101,
    airflow_control_limit_max: 102,
    high_room_control_limit_min: 25,
    high_room_control_limit_max: 24,
    temperature_setpoint: 4,
    airflow_setpoint: 3,
    allow_boost: 359,
    pir_after_run_time: 27,
    co2_control_mode: 412, // {0=No,1=Level,2=RateOfChange,3=Combined}
    co2_control_limit_min: 118,
    co2_control_limit_max: 119,
    tvoc_control_mode: 675, // {0=No,1=Level,2=RateOfChange,3=Combined}
    tvoc_control_limit_min: 664,
    tvoc_control_limit_max: 665,
    room_adaptive_airflow: 549,
    room_volume: 669,

    // Device timers
    timer_settings_timezone_offset: 438,
    timer_settings_daylight_savings_time: 439,
    // Timer x schedule constants are calculated inline in DeviceTiemrSettings.js at the moment
    // timer_1_flags: 441,
    // timer_1_days: 440,
    // timer_1_start_timer: 442,
    // timer_1_duration_minutes: 443,
    // timer_1_flow_per: 444,
    // timer_1_temp: 445,
    // timer_2_flags: 447,
    // timer_2_days: 446,
    // timer_2_start_timer: 448,
    // timer_2_duration_minutes: 449,
    // timer_2_flow_per: 450,
    // timer_2_temp: 451,
    // timer_3_flags: 453,
    // timer_3_days: 452,
    // timer_3_start_timer: 454,
    // timer_3_duration_minutes: 455,
    // timer_3_flow_per: 456,
    // timer_3_temp: 457,
    // timer_4_flags: 459,
    // timer_4_days: 458,
    // timer_4_start_timer: 460,
    // timer_4_duration_minutes: 461,
    // timer_4_flow_per: 462,
    // timer_4_temp: 463,
    // timer_5_flags: 465,
    // timer_5_days: 464,
    // timer_5_start_timer: 466,
    // timer_5_duration_minutes: 467,
    // timer_5_flow_per: 468,
    // timer_5_temp: 469,
    // timer_6_flags: 471,
    // timer_6_days: 470,
    // timer_6_start_timer: 472,
    // timer_6_duration_minutes: 473,
    // timer_6_flow_per: 474,
    // timer_6_temp: 475,
    // timer_7_flags: 477,
    // timer_7_days: 476,
    // timer_7_start_timer: 478,
    // timer_7_duration_minutes: 479,
    // timer_7_flow_per: 480,
    // timer_7_temp: 481,
    timer_night_cool_flags: 483,
    timer_night_cool_days: 482,
    timer_night_cool_start_timer: 484,
    timer_night_cool_duration_minutes: 485,
    timer_night_cool_flow_per: 486,
    timer_night_cool_temp: 487,


    // Start priorities
    priority_1: 91,
    priority_2: 92,
    priority_3: 93,
    priority_4: 94,
    priority_5: 95,
    priority_6: 368,
    priority_7: 369,
    priority_8: 685,
    start_dependency: 90,
    night_cool_independent: 222,

    // Filter settings
    system_condition_bits: 1035, // {0=[Low Temp Process|Inactive|Active],1=[High Temp Process|Inactive|Active],2=[Condensation Process|Inactive|Active],3=[Non Critical Hardware Fault|False|True],4=[Condenser Overheated|False|True],5=[Compressor Locked|False|True],6=[Filter Change Needed|False|True], 7=[High Room Temp|False|True], 8=[Abnormal Filter Test Calibration Result|False|True], 9=[Manual Override Active|False|True],10=[Comfort Cool Defrost Warning |False|True],11=[Comfort Cool Condensation Warning|False|True],12=[Boost Mode Active|False|True],13=[Comfort Cool Hotgas Warning|False|True], 14=[Comfort Cool Pressure Warning|False|True], 15=[Group Master Not Available Warning|False|True] }
    sc_low_temp_process: '1035_0',
    sc_high_temp_process: '1035_1',
    sc_condensation_process: '1035_2',
    sc_non_critial_hardware_fault: '1035_3',
    sc_condenser_overheated: '1035_4',
    sc_compressor_locked: '1035_5',
    sc_filter_change_needed: '1035_6',
    sc_high_room_temp: '1035_7',
    sc_abnormal_filter_test_cal: '1035_8',
    sc_manual_override_active: '1035_9',
    sc_comfort_cool_defrost_warning: '1035_10',
    sc_comfort_cool_cond_warning: '1035_11',
    sc_boost_mode_active: '1035_12',
    sc_comfort_cool_hotgas_warning: '1035_13',
    sc_comfort_cool_pressure_warning: '1035_14',
    sc_group_master_not_available_warning: '1035_15',
    system_alarm_bits: 1036, // {0=[Low Temp Alarm|False|True],1=[Condensation Alarm|False|True],2=[Filter Alarm|False|True],3=[Critical Hardware Fault|False|True]}
    system_alarm_low_temp: "1036_0",
    system_alarm_condensation: "1036_1",
    system_alarm_filter: "1036_2",
    system_alarm_critical_hardware_fault: "1036_3",
    filter_reset_status: 496, // this can be set to 1 to indicate status reset, I guess
    filter_test_mode: 51, // {0=Off,1=Timer,2=Tacho,3=Timer And Tacho, 4=DP-switch only}
    filter_max_life_time_warning: 543,
    filter_life_span_warning: 52,
    filter_flow_alarm: 70,
    filter_max_life_time_alert: 432,
    filter_life_span_alert: 55,


    // === DEVICE SETTINGS END

}

export const storageConstants = {
    userLanguage: "userLanguage",
    latestGitHash: "latestGitHash"
}

export const periodErrors = {
    periodRangeOverlapError: "periodRangeOverlapError"
}


export const settingsModalTypes = {
    settings: "settings",
    periods: "periods"
}

//UserObject to use in combination with User details state
export const userObject = {
    id: "",
    givenName: "",
    surname: "",
    preferredLanguage: "en",
    mail: "",
    mobilePhone: "",
    companyName: "",
    newsletterPref: ""
}
//Name of open extensions used with AAD
export const openExtensions = {
    newsletter: "newsletterPreferences"
}

export const toastTypes = {
    success: "success",
    info: "info",
    warning: "warning",
    error: "error"
};

// GroupObject to use in combination with Group details state
export const emptyGroupObject = {
    id: "",
    parent: "",
    name: "",
    devices: [],
    description: "",
    children: [],
    type: "",
    canEdit: 1
}
//Base object to display as a root in a group hierarchy
export const rootObject = {
    id: "",
    parent: "",
    description: "",
    name: "Airmaster A/S",
    type: "root"
}

export const notificationFrequencyConstants = {MONTHLY: "MONTHLY", WEEKLY: "WEEKLY", DAILY: "DAILY"}
export const notificationDefault = {
    id: "",
    userId: "",
    itemId: "",
    itemType: "group",
    enabled: false,
    frequency: notificationFrequencyConstants.WEEKLY,
    alarms: [
        {name: "alarms", enabled: false},
        {name: "filter_alarm", enabled: false},
    ],
    warnings: [
        {name: "warnings", enabled: false},
        {name: "filter_change_needed", enabled: false}
    ]
}

//Supported system languages
const priv_syslang = [
    {
        id: "da",
        icuCode: "da-DK",
        option: "Dansk"
    },
    {
        id: "en",
        icuCode: "en-UK",
        option: "English"
    },
    {
        id: "de",
        icuCode: "de-DE",
        option: "Deutsch"
    },
    {
        id: "nl",
        icuCode: "nl-NL",
        option: "Nederlands"
    }
]
export const syslang = process.env.REACT_APP_DEBUG_I18N === "true"
    ? priv_syslang.concat({id: "kk", icuCode: "kk-KZ", option: "Placeholder"}) // use Kazakh as placeholder. We need valid lang because microsoft graph throws errors otherwise.
    : priv_syslang

//Supported system roles
export const roleConstants = {
    Admin: "App.Admin",
    Technical: "App.Technical",
    TechnicalBasic: "App.TechnicalBasic",
    Premium: "App.Premium",
    Basic: "App.BasicUser",
    Viever: "App.Viewer"
}

export const allowedPageRoles = {
    DeviceTechnicalPage: [roleConstants.Admin, roleConstants.Technical, roleConstants.TechnicalBasic],
    DeviceParametersPage: [roleConstants.Admin, roleConstants.Technical, roleConstants.TechnicalBasic],
    GroupsPage: [roleConstants.Admin, roleConstants.Technical, roleConstants.TechnicalBasic, roleConstants.Premium],
    NotificationsPage: [roleConstants.Admin, roleConstants.Technical, roleConstants.TechnicalBasic, roleConstants.Premium, roleConstants.Basic],
    TimerPeriodEdit: [roleConstants.Admin, roleConstants.Technical, roleConstants.TechnicalBasic, roleConstants.Premium]
}

export const configurationSubPages = {
    operation: "operation",
    setpoints: "setpoints",
    timers: "timers",
    startpriority: "startpriority",
    filter: "filter"
}

//Loaders which can be displayed upon performing requests to the API
export const loaders = {
    getUsers: "getUsers",
    editUser: "editUser",
    addUser: "addUser",
    deleteUser: "deleteUser",
    inviteUser: "inviteUser",
    updateUserInformation: "updateUserInformation",
    getFirmwareList: "getFirmwareList",
    deleteFirmware: "deleteFirmware",
    editFirmware: "editFirmware",
    createFirmware: "createFirmware",
    updateNewsletterPreferences: "updateNewsletterPreferences",
    updateGroupOrDevice: "updateGroupOrDevice",
    createGroup: "createGroup",
    deleteGroup: "deleteGroup",
    deleteDevice: "deleteDevice",
    updateHierarchy: "updateHierarchy",
    getTelemetry: "getTelemetry",
    getDeviceLog: "getDeviceLog",
    saveConfiguration: "saveConfiguration",
    saveFirmwareConfiguration: "saveFirmwareConfiguration",
    loadCurrentDevice: "loadCurrentDevice"
}
export const loaderErrors = {
    loadCurrentDevice: "loadCurrentDevice",
    queryDeviceList: "queryDeviceList",
    editDevice: "editDevice",
    unauthorizedOperation: "unauthorizedOperation"
}

export const globalErrorTypes = {
    deviceNotFound: "deviceNotFound",
}

export const optionalValidationTypes = {
    leftLessThanRight: "LeftLessThanRight"
}

export const featureFlags = {
    multiDragAndDrop: process.env.REACT_APP_FEATURE_MULTI_DRAG_AND_DROP === "true",
    notifications: process.env.REACT_APP_FEATURE_NOTIFICATIONS === "true",
    calendar: process.env.REACT_APP_FEATURE_CALENDAR === "true",
    awareOperations: process.env.REACT_APP_FEATURE_AWARE_OPERATIONS === "true"
}

export const awareOperations = {
    standby_wating: 0,
    standby_timer: 1,
    standby_permanent: 2,
    comfort: 3,
    boost_ventilation: 4,
    manual_flow: 5,
    manual_temp: 6,
    holiday: 8,
    deep_sleep: 9
}

export const themeColors = {
    getPrimary: () => window.getComputedStyle(document.documentElement).getPropertyValue('--bs-primary'),
    getSecondary: () => window.getComputedStyle(document.documentElement).getPropertyValue('--bs-secondary')
}

export const scheduleBarColors = ["#85b0b6", "#e9fdc0", "#ffc9c9", "#ffe6a6", "#ffa378", "#8fb794", "#c9a9be", "#fffcad"]

export const readOnlyParamsList = ["257", "400", "586", "587", "588", "589", "590", "591", "592", "593", "594", "595", "596", "597"]

export const ignoredErrorsList = [
    'Load failed',
    'Query renewed',
    'Interaction is currently in progress',
    'The user aborted a request',
    'Failed to fetch',
    'NetworkError when attempting to fetch',
    'Fetch is aborted'
];
