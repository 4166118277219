import React, {useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {Gear, InfoCircleFill, Power} from 'react-bootstrap-icons';
import ComboBoxWithMessages from "../../../../components/shared/comboboxwithmessages/ComboBoxWithMessages.js";
import InputGroupWithMessages from "../../../../components/shared/inputgroupwithmessages/InputGroupWithMessages.js";
import {cloneDeep} from "lodash";
import {awareOperations, roleConstants, featureFlags, toastTypes} from "../../../../helpers/constants.js";
import SwitchGroup from "../../../shared/switchgroup/SwitchGroup.js";
import {getAirFlow, getReboot, updateFieldIfChanged, validateParameter} from "../../../../helpers/awareSettingsHelper.js";
import {getParameterToShow} from "../../../../helpers/settingsHelper.js";
import {hasAnyRole} from "../../../../helpers/authHelper.js"
import PopOverDialog from "../../../../components/shared/popoverdialog/PopOverDialog.js";
import {awareParamNames} from "../../../parameters/awareParamSpec.js";
import {awareTelemNames} from "../../../parameters/awareTelemSpec.js";
import {paramNames} from "../../../parameters/parameterSpec.js";
import {addToast} from "../../../../helpers/reduxstore/reducers/toastReducer.js";
import {useDispatch} from "react-redux";
import {useMsal} from "@azure/msal-react"
import DeviceConfigurationRow
    from '../../../../components/dashboard/dashboardstatusbox/devicestatus/configurations/configurationrow/DeviceConfigurationRow.js';
import DeviceConfigTopInformation from "../../../shared/topinformation/DeviceConfigTopInformation.js";

/** Displays the configuration page for operational settings
 * @param {object} device device object
 * @param {string} className classes for the component wrapper
 * @param {function} save save function to trigger displaying group selector or confirmation (for single device)
 */
export default function AwareOperationSettings({device, className, save}) {
    let disableOperationRequest = false
    let disableAirflow = false
    let disableOverrideTime = false
    
    let disableOff = false
    let disableStandby = false
    let disableAuto = false;
    let disableManualStart = false
    let disableBoost = false

    const {t} = useTranslation(['settingspage', 'dashboard', 'telemetry', 'common'])
    const dispatch = useDispatch()
    const {accounts} = useMsal()
    const [oldDeviceState, setOldDeviceState] = useState(cloneDeep(device))
    const [operationRequest, setOperationRequest] = useState({value: -1})
    const [operationRequestOptionsList, setOperationRequestOptionsList] = useState(generateOperationRequestsOptionsList(device))
    const [airflow, setAirflow] = useState(getAirFlow(device, t))
    const [reboot, setReboot] = useState(getReboot(device, t))
    const [overrideTime, setOverrideTime] = useState(getParameterToShow(device, awareParamNames.manual_override_time, t))
    const systemMode = device?.latest_telemetry?.[awareTelemNames.op_mode]
    const startedByValue = device?.latest_telemetry?.[awareTelemNames.started_by]
    const connected = device?.connected

    const startedByText = startedByValue === "5" ?
        <span>CO₂</span> : t('telemetry:AwareStartedBy_' + startedByValue)

    const systemModeAllowedAirflowChange = [101, 102, 103, 104]

    if (systemModeAllowedAirflowChange.indexOf(systemMode) === -1) {
        disableAirflow = true
    }

    if (!connected) {
        disableOperationRequest = true
        disableAirflow = true
        disableOverrideTime = true
    }

    //HACK until operation mode is implemented in aware
    if(!featureFlags.awareOperations) {
        disableOperationRequest = true
    }

    function generateOperationRequestsOptionsList(device) {
        if (device.device_twin.reported.parameters) {
            const allowBoost = device.device_twin.reported.parameters[awareParamNames.allow_boost] ? device.device_twin.reported.parameters[awareParamNames.allow_boost] : true
            const operationMode = device?.latest_telemetry?.[awareTelemNames.op_mode] ? device?.latest_telemetry?.[awareTelemNames.op_mode] : -1

            if (operationMode == awareOperations.standby_permanent || operationMode == awareOperations.deep_sleep) disableOff = true
            if (operationMode == awareOperations.standby_wating || operationMode == awareOperations.standby_timer) disableStandby = true
            if (operationMode == awareOperations.comfort) {
                disableManualStart = true
                disableAuto = true
            }
            if (operationMode == awareOperations.boost_ventilation || !allowBoost) disableBoost = true
        }

        return [
            {id: -1, option: t('settingspage:parameters.mode_options.Choose_Operation_Request')},
            {id: "OFF", option: t('settingspage:parameters.mode_options.OFF'), disabled: disableOff},
            {id: "STANDBY", option: t('settingspage:parameters.mode_options.Standby'), disabled: disableStandby},
            {id: "AUTO", option: t('settingspage:parameters.mode_options.Auto'), disabled: disableAuto},
            {
                id: "MANUAL",
                option: t('settingspage:parameters.mode_options.Manual_Start'),
                disabled: disableManualStart
            },
            {id: "BOOST", option: t('settingspage:parameters.mode_options.Boost'), disabled: disableBoost}
        ]
    }

    // When device is updated after write, update parameters to show / hide messages
    useEffect(() => {        
        const desiredOperationModeRequest = device.device_twin.desired?.parameters?.[awareTelemNames.op_mode]
        
        const messages = [{
            type: "warning",
            text: t('settingspage:setpoints_settings.messages.waiting_for_update')
        }]

        if (desiredOperationModeRequest && (operationRequest.value == -1 || desiredOperationModeRequest == operationRequest.value)) {
            setOperationRequest({value: desiredOperationModeRequest, messages: messages});
        }

        setOperationRequestOptionsList(generateOperationRequestsOptionsList(device))
        updateFieldIfChanged(setAirflow, oldDeviceState, device, awareTelemNames.udf, t)
        updateFieldIfChanged(setOverrideTime, oldDeviceState, device, awareParamNames.manual_override_time, t)
        updateFieldIfChanged(setReboot, oldDeviceState, device, "reboot", t)
        setOldDeviceState(cloneDeep(device))
    }, [device])

    /**
     * validates parameters. Adds messages to viewmodels if fail. returns true if all is validated, otherwise false.
     * @returns {boolean}
     */


    const validateParameters = () => {
        let validatedList = []
        validatedList.push(validateParameter(awareTelemNames.udf, airflow, setAirflow, t))
        validatedList.push(validateParameter(awareParamNames.manual_override_time, overrideTime, setOverrideTime, t))
        return validatedList.every(v => v)
    }

    const getValuesToSave = () => {
        const updated_parameters = {}
        if (operationRequest.value !== -1) {
            updated_parameters[awareTelemNames.op_mode] = operationRequest.value
        }

        const desiredReboot = device.device_twin.desired.reboot
        if(reboot.value && desiredReboot != reboot.value) {
            updated_parameters[paramNames.reboot] = "1"
        }
       
        const reportedUdf = device?.latest_telemetry?.[awareTelemNames.udf]
        if (reportedUdf != airflow.value) {
            if (device.device_twin.desired.parameters[awareTelemNames.udf] && device.device_twin.desired.parameters[awareTelemNames.udf] === airflow.value) {
                console.log(` desired exists and is same for set_air_flow_request: ${device.device_twin.desired.parameters[awareTelemNames.udf]}`)
            } else {
                updated_parameters[awareTelemNames.udf] = parseFloat(airflow.value)
            }
        }

        const reportedManualOverrideTime = device.device_twin.reported.parameters[awareParamNames.manual_override_time]
        if (reportedManualOverrideTime != overrideTime.value) {
            if (device.device_twin.desired.parameters && device.device_twin.desired.parameters[awareParamNames.manual_override_time] && device.device_twin.desired.parameters[awareParamNames.manual_override_time] === overrideTime.value) {
                console.log(` desired exists and is same for ${awareParamNames.manual_override_time}: ${device.device_twin.desired.parameters[awareParamNames.manual_override_time]}`)
            } else {
                updated_parameters[awareParamNames.manual_override_time] = parseInt(overrideTime.value)
            }
        }
        
        return updated_parameters
    }

    function saveIfValidated(saveForGroup) {
        if (!validateParameters()) return

        let valuesToSave = getValuesToSave();
        if (Object.keys(valuesToSave).length === 0) {
            dispatch(addToast({
                id: "OperationSettingsInfo",
                type: toastTypes.info,
                title: "No changes",
                body: "No changes to parameters",
                timeSeconds: 3
            }))
            return
        }
        save(valuesToSave, saveForGroup);
    }

    return (
        <div className={className}>
            <DeviceConfigTopInformation className={'d-xs-none'} title={t('settingspage:operation_settings.title')}
                            subTitle={t('settingspage:operation_settings.description_aware')}/>

            <div className="d-flex desktop-float-left">
                <div className="settings-item-container">
                    <ComboBoxWithMessages
                        className='p-4 w-330px m-auto'
                        label={t('settingspage:parameters.sys_mode')}
                        disabled={disableOperationRequest}
                        inputValue={(e) => setOperationRequest({value: e.target.value})}
                        name='operationRequest'
                        value={operationRequest.value}
                        options={operationRequestOptionsList}
                        messages={operationRequest.messages}
                        infoelement={<>
                            <PopOverDialog popOverTitel={t('settingspage:parameters.sys_mode')}
                                           popOverContent={t('settingspage:infomodals.sys_mode')}
                                           trigger={['hover', 'touch']} contentAsHtml={true} size={'lg'}/>
                        </>}
                    />

                    <div className='p-4 w-330px py-0 py-xl-0 align-items-center'>
                        <DeviceConfigurationRow title={t('dashboard:status.configuration.system_mode')}
                                                isInstalled={true}
                                                value={device.connected ? t('telemetry:AwareSystemMode_' + systemMode) : "-"}
                                                icon={<Gear fill='#44698e'/>}/>
                    </div>

                    <div className='p-4 w-330px py-0 py-xl-0 align-items-center'>
                        <DeviceConfigurationRow title={t('dashboard:status.configuration.started_by')}
                                                isInstalled={true}
                                                value={device.connected ? startedByText : "-"}
                                                icon={<Power fill='#44698e'/>}/>
                    </div>
                </div>
            </div>

            <div className="mt-sm-4 d-flex flex-wrap">
                <div className="settings-item-container">
                    <InputGroupWithMessages
                        label={t('settingspage:parameters.airflow')}
                        className='p-4 w-200px m-auto'
                        value={airflow.value}
                        unit='%'
                        inputValue={(e) => setAirflow({value: e.target.value})}
                        placeholder='%'
                        messages={airflow.messages}
                        disabled={disableAirflow}
                        type='number'
                        min={0}
                        max={100}
                        infoelement={<PopOverDialog popOverTitel={t('settingspage:parameters.airflow')}
                                                    popOverContent={t('settingspage:infomodals.airflow')}
                                                    trigger={['hover', 'touch']}
                                                    contentAsHtml={true}/>}
                    />
                </div>
                
                <div className="settings-item-container">
                    <InputGroupWithMessages
                        label={t('settingspage:parameters.override_time')}
                        className='p-4 w-200px m-auto'
                        value={overrideTime.value}
                        unit={t('common:units.hours')}
                        inputValue={(e) => setOverrideTime({value: e.target.value})}
                        disabled={disableOverrideTime}
                        placeholder={t('common:units.hours')}
                        type='number'
                        messages={overrideTime.messages}
                        infoelement={<PopOverDialog popOverTitel={t('settingspage:parameters.override_time')}
                                                    popOverContent={t('settingspage:infomodals.override_time')}
                                                    trigger={['hover', 'touch']}
                                                    contentAsHtml={true}/>}
                    />
                </div>

                {
                    hasAnyRole(accounts, [roleConstants.Admin, roleConstants.Technical, roleConstants.TechnicalBasic]) && 
                        <div className="settings-item-container">
                            <div>
                                <SwitchGroup
                                    label={t('settingspage:parameters.reboot')}
                                    className='p-4 w-200px m-auto'
                                    checked={reboot.value}
                                    onChange={(e) => setReboot({value: e.target.checked ? true : false})}
                                    messages={reboot.messages}
                                    infoelement={<PopOverDialog popOverTitel={t('settingspage:parameters.reboot')}
                                                                popOverContent={t('settingspage:infomodals.reboot')}
                                                                trigger={['hover', 'touch']}
                                                                contentAsHtml={true}/>}
                                />
                            </div>
                        </div>
                }

            </div>
            <div className="alert alert-info" hidden={!device.slave}>
                <InfoCircleFill className='me-1 mb-1 text-primary'/>{t('settingspage:slave-device-settings')}
                {device.master_serial_number &&
                    <a href={"/device/" + device.master_serial_number + "/configurations/operation"}>{device.master_serial_number}</a>}
            </div>

            {/*=== Button container*/}
            <div className='d-inline-block float-end p-2'>
                <Button variant='outline-secondary' onClick={() => saveIfValidated(false)} className='me-2'>
                    {t('settingspage:save_device')}
                </Button>
            </div>
        </div>
    )
}