import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom';
import {AnyReportedParameters, base64ToUnicode, isAwareDevice} from "../../../helpers/helperfunctions.js";
import {useDispatch, useSelector} from "react-redux";
import NavTopBar from "../../../components/shared/device/NavTopBar";
import {useTranslation} from "react-i18next";
import {
    saveConfigurationByDeviceList,
    saveConfigurationByGroupList
} from "../../../helpers/reduxstore/reducers/groupReducer.js";
import SaveForGroupsModal from "../../../components/settings/saveforgroupsmodal/SaveForGroupsModal.js";
import InformationModal from "../../../components/shared/informationmodal/InformationModal.js";
import {DeviceParameterContent} from "../../../components/parameters/DeviceParameterContent.js";
import {
    findNodeInHierarchyById,
    initTechnicalParamsState,
    updateTechnicalParamsState
} from "../../../helpers/reduxstore/reducers/technicalParamsReducer.js";
import useInterval from "../../../helpers/hooks/useInterval.js";
import useNavigationGuardByRole from "../../../helpers/hooks/useNavigationGuardByRole.js";
import {allowedPageRoles} from "../../../helpers/constants.js";
import {loadCurrentDevice} from "../../../helpers/reduxstore/reducers/deviceReducer.js";
import useSetLocation from "../../../helpers/hooks/useSetLocation.js";
import {isEqual} from "lodash";
import {loadCurrentDeviceWrapper} from "../../../helpers/deviceHelper.js";
import {IfDeviceFound} from "../../../components/dashboard/IfDeviceFound.js";
import {getFirmwareFileForVersion} from "../../../api/api.js";
import {LoadSpinner} from "../../../components/shared/loader/LoadSpinner.js";
import {ParametersTreeView} from "./ParametersTreeView.js";
import {DevicePagesTopInformation} from "../technical/DevicePagesTopInformation.js";

/** Displays the parameters page for an individual device
 * */
export default function GeneratedDeviceParametersPage() {
    useSetLocation()
    useNavigationGuardByRole(allowedPageRoles.DeviceParametersPage)

    const [showLoadSpinner, setShowLoadSpinner] = useState(true)
    const [fileLoadErrorHtml, setFileLoadErrorHtml] = useState("")
    const [warningText, setWarningText] = useState("")
    const [oldFirmwareFileVersion, setOldFirmwareFileVersion] = useState("")

    const {t} = useTranslation(['parameterspage', 'common', 'settingspage'])
    const dispatch = useDispatch()
    const {deviceSerialNumber} = useParams();

    const {hierarchyList, paramSpec, selectedParameterId} = useSelector((state) => state.technicalParams)
    const {currentDevice: device} = useSelector(g => g.device)
    const [selectedParam, setSelectedParam] = useState(null)

    useEffect(() => {
        let newFirmwareParameterVersion = device?.device_twin?.reported?.applicationVersion;

        // TODO: find better solution than hardcoding if no version
        if (!newFirmwareParameterVersion) {
            let fallBackVersion = "1.2.8";
            newFirmwareParameterVersion = fallBackVersion
            setWarningText(`Device has no firmware version so used version '${fallBackVersion}'. Ask Kenneth or Martin for additional information or for changing fallback version.`)
        }

        if (newFirmwareParameterVersion && oldFirmwareFileVersion !== newFirmwareParameterVersion) {
            setOldFirmwareFileVersion(newFirmwareParameterVersion)
            getFirmwareFileForVersion(newFirmwareParameterVersion)
                .then(res => {
                    setFileLoadErrorHtml("")
                    const decodedFileContent = base64ToUnicode(res.fileContent)
                    const initialHierarchy = generateInitialHierarchyFromFirmwareParameterFile(decodedFileContent)
                    dispatch(initTechnicalParamsState(initialHierarchy))
                    dispatch(updateTechnicalParamsState({device: device, t}))
                })
                .catch(err => {
                    console.error(err)
                    setFileLoadErrorHtml(`Tried to download firmware file for parameter version '${newFirmwareParameterVersion}', but it could not be found in Azure blobstorage.<br/><br/>Verify that the file is set in the <a href="/firmware/manage">firmware management page</a>. Include it if not.<br/><br/>If that does not work, ask Martin or Kenneth (or whoever is maintaining Azure at the time of reading) to look in Azure blobstorage for it.`)
                })
                .finally(() => {
                    setShowLoadSpinner(false)
                })
        } else {
            dispatch(updateTechnicalParamsState({device: device, t}))
        }
    }, [device])

    useEffect(() => {
        let foundNode = findNodeInHierarchyById(hierarchyList, selectedParameterId)?.data;
        // only update selected parameter if anything new is found, so value is not reset every 5 seconds
        if (!isEqual(foundNode, selectedParam) || selectedParam == null) {
            setSelectedParam(foundNode)
        }
    }, [device, hierarchyList, selectedParameterId])

    useInterval(() => {
        loadCurrentDeviceWrapper(device, device.serial_number, dispatch)
    }, 5000)

    const [stagedConfiguration, setStagedConfiguration] = useState({})
    const [showGroupModal, setShowGroupModal] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [selectedDeviceIds, setSelectedDeviceIds] = useState([])
    const [selectedGroupIds, setSelectedGroupIds] = useState([])

    const promptForSave = (settings, saveForGroup) => {
        setStagedConfiguration(settings)
        if (saveForGroup) {
            setSelectedDeviceIds([])
            setSelectedGroupIds([])
            setShowGroupModal(true)
        } else setShowConfirmation(true)
    }
    const cancelConfiguration = () => {
        setSelectedDeviceIds([device.id])
        setStagedConfiguration({})
        setShowGroupModal(false)
        setShowConfirmation(false)
    }
    const commitChanges = async () => {
        if (stagedConfiguration) {
            if (selectedDeviceIds.length === 0 && selectedGroupIds.length === 0) {
                await dispatch(saveConfigurationByDeviceList(stagedConfiguration, [device.id], !isAwareDevice(device.serial_number)));
            } else if (selectedDeviceIds.length !== 0) {
                await dispatch(saveConfigurationByDeviceList(stagedConfiguration, selectedDeviceIds, !isAwareDevice(device.serial_number)));
            } else {
                await dispatch(saveConfigurationByGroupList(stagedConfiguration, selectedGroupIds, !isAwareDevice(device.serial_number)));
            }
            await dispatch(loadCurrentDevice(device.serial_number));
        }
        setShowGroupModal(false)
        setShowConfirmation(false)
    }

    // async function downloadFirmwareFile() {
    //     console.log("Downloading firmware file", oldFirmwareFileVersion)
    //     const res = await getFirmwareFile(oldFirmwareFileVersion)
    //     saveBase64String(oldFirmwareFileVersion, res.fileContent)
    // }

    if (!device.connected || !AnyReportedParameters(device)) return <div className='d-flex flex-column flex-grow-1'>
        <DevicePagesTopInformation device={device}/>
        <NavTopBar deviceSerialNumber={deviceSerialNumber} currentPage='parameters'/>
        <div className='d-flex flex-grow-1 bg-white rounded-3 p-3 mb-3'>
            {!device.connected ? t('settingspage:settings_not_available_device_offline') : t('settingspage:settings_not_available_no_parameters')}
        </div>
    </div>

    return (
        <IfDeviceFound>
            {/*=== Modals ===*/}
            <SaveForGroupsModal show={showGroupModal} dismiss={cancelConfiguration}
                                setSelectedGroupIds={setSelectedGroupIds}
                                accept={commitChanges}/>
            <InformationModal show={showConfirmation} title={t('settingspage:confirm_modal.title')}
                              body={t('settingspage:confirm_modal.body')}
                              dismiss={cancelConfiguration} accept={commitChanges}/>

            <div className='d-flex flex-column flex-grow-1'>
                <DevicePagesTopInformation device={device}/>
                <NavTopBar deviceSerialNumber={deviceSerialNumber} currentPage='parameters'/>

                {showLoadSpinner &&
                    <LoadSpinner style={{marginTop: "10vh"}} height='60px' width='60px'/>
                }
                {!showLoadSpinner &&
                    <>
                        {warningText && <p className={'p-2'}>{warningText}</p>}
                        {!fileLoadErrorHtml &&
                            <div className="d-block d-md-flex">

                                <ParametersTreeView hierarchyList={hierarchyList}
                                                    selectedParameterId={selectedParameterId}
                                                    showId={false}/>

                                <DeviceParameterContent className='d-flex flex-column h-100'
                                                        selectedParameter={selectedParam}
                                                        promptForSave={promptForSave} isAware={true}
                                                        dynamicParamSpec={paramSpec}/>

                            </div>
                        }
                        {fileLoadErrorHtml &&
                            <p className={'p-2'} dangerouslySetInnerHTML={{__html: fileLoadErrorHtml}}/>}
                    </>
                }
            </div>
        </IfDeviceFound>
    )
}

function generateInitialHierarchyFromFirmwareParameterFile(fileContent) {
    // map remote parameters to layout parameters to show
    const parsedRemoteParameterList = JSON.parse(fileContent)
    const parentNodeList = parsedRemoteParameterList.filter(o => o.type === "parent").map(o => ({
        name: o.text,
        type: "category",
        id: o.id,
        parent: o.parent,
        isCollapsed: true,
        isVisibleToTechnicalBasic: true,
        children: []
    }))
    const IdParentNodeMap = parentNodeList.map(o => ([o.id, o])).toMap()
    parentNodeList.forEach(o => { // Glue category nodes together
        const parentNode = IdParentNodeMap[o.parent]
        if (parentNode) parentNode.children.push(o)
    })

    const parameterNodeList = parsedRemoteParameterList.filter(o => o.type === "child").map(child => ({
        id: child.data.text,
        parent: child.parent,
        name: child.data.text,
        name_orig: child.text,
        desc: child.data.description,
        type: mapType(child.data.type),
        min: parseFloat(child.data.minvalue),
        max: parseFloat(child.data.maxvalue),
        // res: 1, // Not known in new file
        default: child.data.value,
        unit: child.data.unit,
        options: child.data.type === "enum" ?
            child.data.enum.substring(1, child.data.enum.length - 1).split(",")
                .map(s => s.trim().split("=").map(e => e.trim()))
                .toMap() : ""
    }))
    parameterNodeList.forEach(o => { // Glue parameter nodes on parents
        const parentNode = IdParentNodeMap[o.parent]
        if (parentNode) parentNode.children.push({
            type: "parameter",
            isVisibleToTechnicalBasic: true, // TODO: how to see if should be visible
            data: o
        })
    })

    return {
        paramSpec: parameterNodeList.map(p => [p.id, p]).toMap(),
        hierarchyList: [
            ...parentNodeList.filter(o => o.parent === "#"),
            {
                name: "All Parameters",
                type: "category",
                id: "5fa389d5-dcc3-48f0-adc7-c4c15215a2f0",
                isCollapsed: true,
                isVisibleToTechnicalBasic: true,
                children: parameterNodeList.map(p => ({
                    type: "parameter",
                    isVisibleToTechnicalBasic: true, // TODO: how to see if should be visible
                    data: p
                }))
            }
        ]
    }
}

const mapType = (type) => {
    switch (type) {
        case 'float':
            return 'decimal'
        case 'uint32_t':
            return 'int'
        case 'uint16_t':
            return 'int'
        case 'uint8_t':
            return 'int'
        case 'int32_t':
            return 'int'
        case 'int16_t':
            return 'int'
        default:
            return type
    }
}