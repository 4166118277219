// noinspection EqualityComparisonWithCoercionJS

import {ChevronDoubleDown, ChevronDown, ChevronUp} from "react-bootstrap-icons";
import TimerScheduleListWithWeekGraphic from "./TimerScheduleListWithWeekGraphic.js";
import {setCharAt} from "../../../../helpers/helperfunctions.js";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import PopOverDialog from "../../../shared/popoverdialog/PopOverDialog.js";
import {periodErrors, scheduleBarColors} from "../../../../helpers/constants.js";
import ComboBoxWithMessages from "../../../shared/comboboxwithmessages/ComboBoxWithMessages.js";
import {DatePicker} from '@mui/x-date-pickers'
import moment from 'moment'
import {
    getTimeHHmmStringFromMinutesSinceMidnight,
} from "../../../../helpers/helperfunctions.js";

export function DeviceTimerPeriod({
                                      period, periodActiveId, periodColor, deletePeriod, editPeriod, movePeriodUp,
                                      periodListLenght, movePeriodDown, deviceIsSlave, masterSerialNumber, index,
                                      scheduleList, setScheduleList, setUpdatedScheduleList, userCanEdit, MAX_SCHEDULE_NUM, isAware
                                  }) {
    const {t} = useTranslation(['settingspage', 'common'])
    const today = new Date()

    const [periodFrom, setPeriodFrom] = useState(moment(`${today.getFullYear()}-${period.from_month_day}`, 'YYYY-MM-DD'))
    const [periodTo, setPeriodTo] = useState(moment(`${today.getFullYear()}-${period.to_month_day}`, 'YYYY-MM-DD'))

    const isDefault = () => period.id === "default"
    const getPeriodIsActive = () => period.id === periodActiveId || (!periodActiveId && isDefault())

    const [periodIsOpen, setPeriodIsOpen] = useState(getPeriodIsActive() || period.newly_created)
    const [periodInEditMode, setPeriodInEditMode] = useState(period.newly_created ?? false)

    function updateScheduleListItemProp(itemKey, objKey, newValue) {
        console.log(`Trying to update scheduleItem: (itemKey:${itemKey}, objKey:${objKey}, newValue:${newValue})`)
        let updatedScheduleList = scheduleList.map(item => item.key === itemKey ? {...item, [objKey]: newValue} : item);
        
        if(objKey === "timerDuration") {
            let schedule = updatedScheduleList.find(item => item.key === itemKey)
            let startTime = schedule.timerStartMinutesSinceMidnight
            setUpdatedScheduleList(period.id, period.name, itemKey, objKey, getTimeHHmmStringFromMinutesSinceMidnight(startTime + newValue))
        }else{
            setUpdatedScheduleList(period.id, period.name, itemKey, objKey, newValue)
        }

        setScheduleList(period.id, updatedScheduleList)
    }

    const getDayIsChecked = (dayBits, index) => dayBits[index] === '1'

    const setDayIsChecked = (itemKey, dayBits, index, checked) => {
        const updatedDayBits = setCharAt(dayBits, index, checked ? '1' : '0')
        updateScheduleListItemProp(itemKey, "dayBits", updatedDayBits)
    }

    const findNextScheduleKey = () => {
        for (let i = 0; i <= MAX_SCHEDULE_NUM; i++) {
            let schedule = scheduleList.find(s => s.key === i)
            if (!schedule) return i
        }
        return scheduleList.length
    }

    const addSchedule = () => {
        const DEFAULT_DAYBITS = process.env.REACT_APP_DEVICE_TIMER_SETTINGS_DEFAULT_DAYBITS;
        const DEFAULT_STARTTIME = parseInt(process.env.REACT_APP_DEVICE_TIMER_SETTINGS_DEFAULT_STARTTIME);
        const DEFAULT_ENDTIME = parseInt(process.env.REACT_APP_DEVICE_TIMER_SETTINGS_DEFAULT_ENDTIME);
        const DEFAULT_FLOWPERCENT = parseInt(process.env.REACT_APP_DEVICE_TIMER_SETTINGS_DEFAULT_FLOWPERCENT);
        const DEFAULT_TEMP_CELCIUS = parseInt(process.env.REACT_APP_DEVICE_TIMER_SETTINGS_DEFAULT_TEMP_CELCIUS);

        const newKey = findNextScheduleKey();

        const createNewSchedule = (key, isUpdate = false) => ({
            key,
            title: `${t('common:general.schedule')} ${key}`,
            dayBits: DEFAULT_DAYBITS,
            timerStartMinutesSinceMidnight: DEFAULT_STARTTIME,
            timerDuration: isUpdate ? getTimeHHmmStringFromMinutesSinceMidnight(DEFAULT_STARTTIME + DEFAULT_ENDTIME) : DEFAULT_ENDTIME,
            flowPercent: DEFAULT_FLOWPERCENT,
            temperatureCelcius: DEFAULT_TEMP_CELCIUS,
            inEditMode: true,
            isEnabled: true,
            isWaitingForUpdate: false,
            barColor: scheduleBarColors[scheduleList.length]
        });

        const newSchedule = createNewSchedule(newKey);
        setScheduleList(period.id, [...scheduleList, newSchedule]);

        const newScheduleUpdate = createNewSchedule(newKey, true);
        setUpdatedScheduleList(period.id, period.name, newKey, "addSchedule", newScheduleUpdate);
    }

    /** Remove item by key and update keys and title of remaining items
     * @param key
     */
    const removeSchedule = (key) => {
        setScheduleList(period.id, scheduleList
            .filter(i => i.key !== key)
            .map((item, idx) => {
                return {
                    ...item,
                    key: idx,
                    title: idx === 0 ? t('settingspage:device_timers.modes.NightCool') : `${t('common:general.schedule')} ${idx}`
                }
            }));

        setUpdatedScheduleList(period.id, period.name, key, "removeSchedule", true);
    }

    function getPeriodReadonlyTitle(period) {
        if (period.from_month_day && period.to_month_day) {
            const fromSplit = period.from_month_day.split("-")
            const toSplit = period.to_month_day.split("-")
            return <>
                <span
                    style={{fontWeight: 'bold'}}>{t('settingspage:device_timers.start_date')}</span>: {fromSplit[1]}/{fromSplit[0]}<span
                className="px-2">-</span><span
                style={{
                    fontWeight: 'bold',
                    marginLeft: '3px'
                }}>{t('settingspage:device_timers.end_date')}</span>: {toSplit[1]}/{toSplit[0]}
            </>
        }
        return <div/> // default period does not have from / to
    }

    function anyPeriodErrors() {
        return period.period_error_map && Object.entries(period.period_error_map).length !== 0;
    }

    function getPeriodBorder() {
        return anyPeriodErrors() ? '#d3745f 2px solid' : '#EEEEEE 2px solid'
    }

    let systemConditionPeriodList = [
        {id: 0, option: t('settingspage:parameters.mode_options.OFF')},
        {id: 3, option: t('settingspage:parameters.mode_options.Holiday_Mode')},
        {id: 4, option: t('settingspage:parameters.mode_options.Auto')}
    ]

    return (<>

        {/* Edit / delete period buttons. Cannot modify default. */}
        {!isDefault() && userCanEdit &&
            <div className="d-flex px-2 justify-content-between">
                <div hidden={deviceIsSlave} className={'device-timer-settings-item-anchor'}
                     onClick={() => setPeriodInEditMode(!periodInEditMode)}>
                    <span>{periodInEditMode ? t('settingspage:device_timers.end_edit') : t('settingspage:device_timers.edit_period')}</span>
                </div>
                <div className={'device-timer-settings-item-anchor'} hidden={deviceIsSlave}
                     onClick={() => deletePeriod(period.id, period.name)}>{t('settingspage:device_timers.delete_period')}</div>
            </div>}

        {/* Period accordion */}
        <div className={`mb-3 ${isDefault() ? 'mt-4' : ''}`}
             style={{border: getPeriodBorder(), borderRadius: '3px'}}>
            <div
                className={`d-xs-block d-md-flex justify-content-between align-items-center ${periodInEditMode ? '' : 'cursor-pointer'}`}
                onClick={() => {
                    if (!periodInEditMode) setPeriodIsOpen(!periodIsOpen)
                }}
                style={{
                    background: '#fafafa', padding: '0.75rem',
                    borderBottom: periodIsOpen ? '#EEEEEE 2px solid' : '0'
                }}>

                {/* === Period Name === */}
                <h5 className="m-0 mb-xs-2 d-flex">
                    {periodInEditMode &&
                        <div className="me-2" style={{fontSize: '1rem', marginTop: '-2px'}}>
                            <div style={{height: "16px"}}>
                                {index !== 0 &&
                                    <ChevronUp className="cursor-pointer" style={{verticalAlign: 'initial'}}
                                               onClick={() => movePeriodUp(period.id)}/>
                                }
                            </div>
                            <div style={{height: "16px"}}>
                                {index < periodListLenght - 2 &&
                                    <ChevronDown className="cursor-pointer" style={{verticalAlign: 'initial'}}
                                                 onClick={() => movePeriodDown(period.id)}/>
                                }
                            </div>
                        </div>
                    }
                    {periodInEditMode &&    
                    <div className="mb-xs-2">
                        <div className="d-flex align-items-center">
                            <input type="text"
                                   value={period.id === "default" ? t('settingspage:device_timers.default_period_popover_dialog.title') : period.name}
                                   onChange={e => editPeriod({...period, name: e.target.value})}/>
                        </div>           

                            {period.period_error_map && period.period_error_map[periodErrors.periodNameError] &&
                                <span className="color-error">{period?.period_error_map[periodErrors.periodNameError]}</span>
                            }

                    </div>
                    }
                    {!periodInEditMode &&
                        <span>{period.id === "default" ? t('settingspage:device_timers.default_period_popover_dialog.title') : period.name}</span>}
                    {isDefault() &&
                        <PopOverDialog
                            popOverTitel={t('settingspage:device_timers.default_period_popover_dialog.title')}
                            popOverContent={t('settingspage:device_timers.default_period_popover_dialog.description')}
                            trigger={['hover', 'touch']} contentAsHtml={false}/>}
                    <span>{getPeriodIsActive() ? <PeriodActiveText t={t}/> : <PeriodInactiveText t={t}/>}</span>
                </h5>

                {/* === Period MonthDay Range === */}
                {periodInEditMode &&
                    <div className="mb-xs-2">
                        <div className="d-flex align-items-center">
                            <DatePicker className="bg-white" disableHighlightToday={true}
                                        value={periodFrom} views={['month', 'day']} format={'DD/MM'}
                                        onChange={v => {
                                            setPeriodFrom(v); editPeriod({...period, from_month_day: v.format('MM-DD')})
                                        }}
                            />
                            <span className="text-bold mx-2">-</span>
                            <DatePicker className="bg-white" disableHighlightToday={true}
                                        value={periodTo} views={['month', 'day']} format={'DD/MM'}
                                        onChange={v => {
                                            setPeriodTo(v); editPeriod({...period, to_month_day: v.format('MM-DD')})
                                        }}
                            />
                        </div>
                        {period.period_error_map && period.period_error_map[periodErrors.periodRangeOverlapError] &&
                            <span
                                className="color-error">{period?.period_error_map[periodErrors.periodRangeOverlapError]}</span>
                        }
                    </div>
                }
                {!periodInEditMode &&
                    <div className="mb-xs-2">
                        <div className="d-flex align-items-center">{getPeriodReadonlyTitle(period)}</div>
                        {period.period_error_map && period.period_error_map[periodErrors.periodRangeOverlapError] &&
                            <span
                                className="color-error">{period?.period_error_map[periodErrors.periodRangeOverlapError]}</span>
                        }
                    </div>
                }


                {/* === System Mode Dropdown === */}
                {!isDefault() &&
                    <div className="d-flex align-items-center">
                        <div>
                            <b>{t('settingspage:parameters.sys_mode')}:</b>
                            <PopOverDialog popOverTitel={t('settingspage:parameters.sys_mode')}
                                           popOverContent={t('settingspage:infomodals.sys_mode')}
                                           trigger={['hover', 'touch']} contentAsHtml={true} size={'lg'}/>
                        </div>
                        {periodInEditMode &&
                            <ComboBoxWithMessages
                                style={{width: '200px'}}
                                className="p-2 m-md-auto"
                                inputValue={e => editPeriod({...period, system_mode: e.target.value})}
                                name="systemMode"
                                value={period?.system_mode ?? '4'}
                                options={systemConditionPeriodList}
                            />
                        }
                        {!periodInEditMode &&
                            <div>{systemConditionPeriodList?.find(e => e.id == (period?.system_mode ?? 4))?.option ?? ""}</div>}
                    </div>
                }

                {/* === Open indicator and period color === */}
                <div className="d-flex align-items-center">
                    <div className="device-timer-circle" style={{background: periodColor}}/>
                    <ChevronDoubleDown className={`m-1 mx-2 transition-250 ${periodIsOpen ? '' : 'flipped'}`}/>
                </div>
            </div>

            {/*List of periods*/}
            {periodIsOpen && <div className="p-2">
                {(period?.system_mode != '0' && period?.system_mode != '3') &&
                    <TimerScheduleListWithWeekGraphic scheduleList={scheduleList}
                                                      updateScheduleListItemProp={updateScheduleListItemProp}
                                                      removeSchedule={removeSchedule}
                                                      setDayIsChecked={setDayIsChecked}
                                                      getDayIsChecked={getDayIsChecked} addSchedule={addSchedule}
                                                      deviceIsSlave={deviceIsSlave} isAware={isAware}
                                                      masterSerialNumber={masterSerialNumber}
                                                      MAX_SCHEDULE_NUM={MAX_SCHEDULE_NUM}
                                                      userCanEdit={userCanEdit || periodActiveId === period.id}
                    />
                }
                {!(period?.system_mode != '0' && period?.system_mode != '3') &&
                    <div style={{fontSize: '18px'}}
                         className="ms-2">{t('settingspage:parameters.only_auto_config')}</div>
                }
            </div>}
        </div>
    </>)
}

function PeriodActiveText({t}) {
    return <span><span className="mx-1">-</span><span
        className="color-telemetry-very-good">{t('settingspage:active.capitalized')}</span></span>
}

function PeriodInactiveText({t}) {
    return <span><span className="mx-1">-</span><span
        style={{color: '#44698e'}}>{t('settingspage:inactive')}</span></span>
}
