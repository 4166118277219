import React, {useState} from 'react'
import InformationModal from '../../../components/shared/informationmodal/InformationModal'
import {
    saveConfigurationByDeviceList,
    saveConfigurationByGroupList,
    saveTimerPeriodsByDeviceList, saveTimerPeriodsByGroupList
} from '../../../helpers/reduxstore/reducers/groupReducer'
import {useDispatch} from 'react-redux'
import MenuTabs from '../menutabs/MenuTabs'
import SaveForGroupsModal from '../saveforgroupsmodal/SaveForGroupsModal'
import SubPagesSettings from "../subpages/SubPagesSettings.js";
import {useTranslation} from "react-i18next";
import {loadCurrentDeviceWrapper} from "../../../helpers/deviceHelper.js";
import {useNavigate, useParams} from "react-router-dom";
import {configurationSubPages, settingsModalTypes} from "../../../helpers/constants.js";
import {isAwareDevice} from "../../../helpers/helperfunctions.js";
import {deviceSaveForGroupValidation} from "../../../api/api"

/** Renders the wrapper for the individual setting pages
 * @param {object} device device data
 */
export default function SettingsContent({device}) {
    const {t} = useTranslation(['settingspage', 'common'])
    const dispatch = useDispatch()
    const {deviceSerialNumber, subPage} = useParams()
    const [stagedChanges, setStagedChanges] = useState({})
    const [type, setType] = useState(settingsModalTypes.settings)
    const [showGroupModal, setShowGroupModal] = useState(false)
    const [showGroupWarningModal, setShowGroupWarningModal] = useState(false)
    const [showConfirmation, setShowConfirmation] = useState(false)
    const [selectedDeviceIds, setSelectedDeviceIds] = useState([device.id])
    const [selectedGroupIds, setSelectedGroupIds] = useState([])
    const navigate = useNavigate()
    const isAware = isAwareDevice(device.serial_number)

    function getCurrentPage() {
        const validPages = [configurationSubPages.operation, configurationSubPages.setpoints,
            configurationSubPages.timers, configurationSubPages.startpriority, configurationSubPages.filter]
        if (!validPages.includes(subPage)) return configurationSubPages.operation
        return subPage
    }

    const promptForSave = (changes, saveForGroup, type = settingsModalTypes.settings) => {
        setStagedChanges(changes)
        setType(type)
        if (saveForGroup) {
            setSelectedDeviceIds([])
            setSelectedGroupIds([])
            setShowGroupModal(true)
        } else setShowConfirmation(true)
    }
    const cancelConfiguration = () => {
        setSelectedDeviceIds([device.id])
        setStagedChanges({})
        setShowGroupModal(false)
        setShowConfirmation(false)
    }

    const saveForGroupValidation = async () => {
        if (selectedGroupIds.length === 0) {
            commitChanges()
            return;
        }
    
        const result = await deviceSaveForGroupValidation(selectedGroupIds, !isAware);
 
        if (result.validationOk == true) {
            commitChanges()
        } else {
            setShowGroupWarningModal(true);
        }
    }    


    const cancelShowGroupWarningModal = () => {
        setShowGroupWarningModal(false)
    }

    const acceptShowGroupWarningModal = () => {
        setShowGroupWarningModal(false)
        commitChanges()
    }

    const commitChanges = async () => {
        if (stagedChanges && (selectedDeviceIds.length !== 0 || selectedGroupIds.length !== 0)) {
            if (type === settingsModalTypes.settings) {
                if (selectedDeviceIds.length !== 0) {
                    await dispatch(saveConfigurationByDeviceList(stagedChanges, selectedDeviceIds, !isAware));
                } else {
                    await dispatch(saveConfigurationByGroupList(stagedChanges, selectedGroupIds, !isAware));
                }
            } else if (type === settingsModalTypes.periods) {
                if (selectedDeviceIds.length !== 0) {
                    await dispatch(saveTimerPeriodsByDeviceList(stagedChanges, selectedDeviceIds, !isAware));
                } else {
                    await dispatch(saveTimerPeriodsByGroupList(stagedChanges, selectedGroupIds, !isAware));
                }
            }
            loadCurrentDeviceWrapper(device, device.serial_number, dispatch);
        }
        setShowGroupModal(false)
        setShowConfirmation(false)
    }

    return (
        <>
            {/*=== Modals ===*/}
            <SaveForGroupsModal show={showGroupModal} dismiss={cancelConfiguration}
                                setSelectedGroupIds={setSelectedGroupIds}
                                accept={saveForGroupValidation}/>
            <InformationModal show={showConfirmation} title={t('settingspage:confirm_modal.title')}
                              body={t('settingspage:confirm_modal.body')}
                              dismiss={cancelConfiguration} accept={commitChanges}/>
            <InformationModal show={showGroupWarningModal} title={t('settingspage:save_group_warning_modal.title')}
                              body={t('settingspage:save_group_warning_modal.body')}
                              dismiss={cancelShowGroupWarningModal} accept={acceptShowGroupWarningModal}/>


            {/*=== Desktop ===*/}
            <div className='d-flex flex-grow-1 bg-white rounded-3 p-3 mb-3 d-xs-none'>
                <MenuTabs selectedSubSettingsPage={getCurrentPage()}
                          setSelectedSubSettingsPage={page => navigate(`/device/${deviceSerialNumber}/configurations/${page}`)}/>
                <SubPagesSettings className="px-sm-4 pb-sm-4 w-100" device={device}
                                  selectedSubSettingsPage={getCurrentPage()}
                                  promptForSave={promptForSave}/>
            </div>

            {/*=== Mobile ===*/}
            <div className='d-sm-none'>
                <select onChange={e => navigate(`/device/${deviceSerialNumber}/configurations/${e.target.value}`)}
                        value={getCurrentPage()} className="form-select shadow-none btn-md form-control">
                    <option value={configurationSubPages.operation}>{t('settingspage:menu.operation')}</option>
                    <option value={configurationSubPages.setpoints}>{t('settingspage:menu.setpoints')}</option>
                    <option value={configurationSubPages.timers}>{t('settingspage:menu.timers')}</option>
                    <option value={configurationSubPages.startpriority}>{t('settingspage:menu.priorities')}</option>
                    <option value={configurationSubPages.filter}>{t('settingspage:menu.filter')}</option>
                </select>
                <div className='p-0 mt-1'>
                    <SubPagesSettings device={device} selectedSubSettingsPage={getCurrentPage()}
                                      promptForSave={promptForSave}/>
                </div>
            </div>
        </>
    )
}