import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { isAwareDevice } from '../../../helpers/helperfunctions.js';
import { useDispatch, useSelector } from "react-redux";
import NavTopBar from "../../../components/shared/device/NavTopBar";
import { useTranslation } from "react-i18next";
import { ChevronDoubleDown } from "react-bootstrap-icons";
import TelemetryCharts from "../../../components/dashboard/telemetrycharts/TelemetryCharts.js";
import { updateSelectedTechnicalGraphs } from "../../../helpers/reduxstore/reducers/telemetryReducer.js";
import { allowedPageRoles } from "../../../helpers/constants.js";
import { JEDeviceTechnicalTelemetryOverview } from "../../../components/parameters/JEDeviceTechnicalTelemetryOverview.js";
import {
    AwareDeviceTechnicalTelemetryOverview
} from "../../../components/parameters/AwareDeviceTechnicalTelemetryOverview.js";
import {
    initTechnicalParamsState,
    updateTechnicalParamsState
} from "../../../helpers/reduxstore/reducers/technicalParamsReducer.js";
import { getDeviceParamsStateStructure } from "../../../components/parameters/getDeviceParamsStateStructure.js";
import useNavigationGuardByRole from "../../../helpers/hooks/useNavigationGuardByRole.js";
import useSetLocation from "../../../helpers/hooks/useSetLocation.js";
import useInterval from "../../../helpers/hooks/useInterval.js";
import { loadCurrentDeviceWrapper } from "../../../helpers/deviceHelper.js";
import { IfDeviceFound } from "../../../components/dashboard/IfDeviceFound";
import { modifyCurrentDevice } from "../../../helpers/reduxstore/reducers/deviceReducer.js";
import { isAdmin } from "../../../helpers/authHelper.js";
import { useMsal } from "@azure/msal-react";
import { DevicePagesTopInformation } from "./DevicePagesTopInformation.js";
import {
    graphTypes, isGraphValidForDevice
} from "../../../components/dashboard/telemetrycharts/telemetryChartsHelper.js";
import useSetAmxOfflineIfTelemetryOld from "../../../helpers/hooks/useSetAmxOfflineIfTelemetryOld.js";

/** Displays the technical page for an individual device */
export default function DeviceTechnicalPage() {
    useSetLocation();
    useNavigationGuardByRole(allowedPageRoles.DeviceTechnicalPage);
    useSetAmxOfflineIfTelemetryOld();
    const { accounts } = useMsal();

    const { t } = useTranslation(['technicalpage', 'common']);
    const dispatch = useDispatch();
    const [deviceParametersHidden, setDeviceParametersHidden] = useState(false);
    const { hierarchyList } = useSelector(s => s.technicalParams);
    const [deviceNotes, setDeviceNotes] = useState("");
    const [oldDeviceNotes, setOldDeviceNotes] = useState("");
    const notesTimeoutRef = useRef(null);
    const { deviceSerialNumber } = useParams();
    const { currentDevice: device } = useSelector(t => t.device);
    const { selectedTechnicalGraphs } = useSelector(t => t.telemetry);

    useEffect(() => {
        if (!isAwareDevice(device.serial_number)) {
            if (hierarchyList.length === 0) dispatch(initTechnicalParamsState(getDeviceParamsStateStructure()));
            dispatch(updateTechnicalParamsState({ device: device, t }));
        }
        if ((device.notes || device.notes === "") && device.notes !== oldDeviceNotes) {
            setDeviceNotes(device.notes);
            setOldDeviceNotes(device.notes);
        }
    }, [device]);

    // ask for updates on a timer
    useInterval(async () => {
        loadCurrentDeviceWrapper(device, deviceSerialNumber, dispatch);
    }, 15000);

    // define valid graphs for device
    const graphOptionList = [
        ...isAwareDevice(deviceSerialNumber) ? [graphTypes.simpleCO2] : [],
        ...isGraphValidForDevice(device, graphTypes.multiCO2) ? [graphTypes.multiCO2] : [],
        ...isGraphValidForDevice(device, graphTypes.multiTVOC) ? [graphTypes.multiTVOC] : [],
        ...isGraphValidForDevice(device, graphTypes.simpleAirflow) ? [graphTypes.simpleAirflow] : [],
        ...isGraphValidForDevice(device, graphTypes.multiHumidity) ? [graphTypes.multiHumidity] : [],
        ...isGraphValidForDevice(device, graphTypes.multiTemperature) ? [graphTypes.multiTemperature] : [],
        ...isGraphValidForDevice(device, graphTypes.multiTemperaturesCooling) ? [graphTypes.multiTemperaturesCooling] : [],
        ...isGraphValidForDevice(device, graphTypes.multiTemperaturesHeatPump) ? [graphTypes.multiTemperaturesHeatPump] : [],
        ...isGraphValidForDevice(device, graphTypes.multiDampersAndSetpoints) ? [graphTypes.multiDampersAndSetpoints] : [],
        ...isGraphValidForDevice(device, graphTypes.multiCondensateSystem) ? [graphTypes.multiCondensateSystem] : [],
        ...isGraphValidForDevice(device, graphTypes.multiAirflowM3H) ? [graphTypes.multiAirflowM3H] : [],
        ...isGraphValidForDevice(device, graphTypes.stepSystemMode) ? [graphTypes.stepSystemMode] : [],
        ...isGraphValidForDevice(device, graphTypes.awareStepSystemMode) ? [graphTypes.awareStepSystemMode] : [],
        ...isGraphValidForDevice(device, graphTypes.stepStartedBy) ? [graphTypes.stepStartedBy] : [],
        ...isGraphValidForDevice(device, graphTypes.awareStepStartedBy) ? [graphTypes.awareStepStartedBy] : [],
        ...isGraphValidForDevice(device, graphTypes.stepMainState) ? [graphTypes.stepMainState] : [],
        ...isGraphValidForDevice(device, graphTypes.stepRecirculationState) ? [graphTypes.stepRecirculationState] : [],
        ...isGraphValidForDevice(device, graphTypes.multiSystemConditions) ? [graphTypes.multiSystemConditions] : [],
        ...isGraphValidForDevice(device, graphTypes.multiSystemAlarms) ? [graphTypes.multiSystemAlarms] : [],
        ...isGraphValidForDevice(device, graphTypes.heating_cooling_with_eev) ? [graphTypes.heating_cooling_with_eev] : [],
        ...isGraphValidForDevice(device, graphTypes.multiPowerConsumption) ? [graphTypes.multiPowerConsumption] : [],
        ...isGraphValidForDevice(device, graphTypes.simpleCoolingPercent) ? [graphTypes.simpleCoolingPercent] : [],
        ...isGraphValidForDevice(device, graphTypes.multiFanRpm) ? [graphTypes.multiFanRpm] : [],
        ...isGraphValidForDevice(device, graphTypes.heater) ? [graphTypes.heater] : []
    ];

    const setGraphSelections = (payload) => {
        dispatch(updateSelectedTechnicalGraphs(payload));
    };

    const defaultGraphList = isAwareDevice(device.serial_number)
        ? [graphTypes.simpleCO2, graphTypes.multiTemperature]
        : [
            ...device.installed_components.co2_sensor ? [graphTypes.multiCO2] : [],
            ...device.installed_components.tvoc_sensor ? [graphTypes.multiTVOC] : [],
            ...!device.installed_components.co2_sensor && !device.installed_components.tvoc_sensor ? [graphTypes.simpleAirflow] : [],
            ...device.installed_components.humidity_control_sensor ? [graphTypes.multiHumidity] : []
        ];

    function SaveDeviceNote(event) {
        if (notesTimeoutRef.current) window.clearTimeout(notesTimeoutRef.current);
        const newNotes = event.target.value;
        if (newNotes === device.notes) return;
        setDeviceNotes(newNotes);
        notesTimeoutRef.current = window.setTimeout(() => {
            dispatch(modifyCurrentDevice(
                { id: device.id, notes: newNotes },
                {
                    type: "success",
                    title: t("dashboard:toasts.saveDeviceNote.title"),
                    body: t("dashboard:toasts.saveDeviceNote.body"),
                    timeSeconds: 3
                })).then();
        }, 2000);
    }

    return (
        <IfDeviceFound>
            <div className="d-flex flex-column flex-grow-1">
                <DevicePagesTopInformation device={device} />
                <NavTopBar deviceSerialNumber={deviceSerialNumber} currentPage="technical"></NavTopBar>

                {/*=== Device Parameters Box ===*/}
                <div className="boxstyling mb-3">
                    <div className="d-flex p-3">
                        <h4 className="">{t('technicalpage:parametersview.title')}</h4>
                        <div className="d-flex align-items-center ms-auto p-1 cursor-pointer"
                            onClick={() => setDeviceParametersHidden(!deviceParametersHidden)}>
                            <div style={{ width: '16px' }} className="me-2">
                                <ChevronDoubleDown
                                    className={`me-2 transition-250 ${deviceParametersHidden ? 'flipped' : ''}`} />
                            </div>
                            <span>{deviceParametersHidden ? t('technicalpage:parametersview.show') : t('technicalpage:parametersview.hide')}</span>
                        </div>
                    </div>
                    {!deviceParametersHidden &&
                        <>
                            <hr className={`m-0`} />
                            <div className="p-2">
                                {!isAwareDevice(deviceSerialNumber) &&
                                    <JEDeviceTechnicalTelemetryOverview device={device} />
                                }
                                {isAwareDevice(deviceSerialNumber) &&
                                    <AwareDeviceTechnicalTelemetryOverview device={device} />
                                }
                            </div>
                        </>
                    }
                </div>

                {isAdmin(accounts) &&
                    <textarea className="border-dim rounded-3 p-1 mt-auto mb-3"
                        style={{ minHeight: '75px', fontSize: '12px' }}
                        onChange={SaveDeviceNote} value={deviceNotes}
                        placeholder={t("dashboard:notes.placeholder")} />
                }

                {/*=== Telemetry Charts ===*/}
                <TelemetryCharts device={device} graphOptionList={graphOptionList}
                    graphSelections={selectedTechnicalGraphs}
                    setGraphSelections={setGraphSelections} defaultGraphList={defaultGraphList}
                    className="boxstyling p-3 mb-3" />

            </div>
        </IfDeviceFound>
    );
}